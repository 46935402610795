bovoApp.controller('MainController', ['$scope', 'Setting', '$location', '$localStorage', '$rootScope', 'User', '$routeParams', '$timeout', '$window', '$filter', 'socket', 'Order', 'FileUploader', '$http', 'Worker', 'Customer', 'tourConfig', 'WizardHandler', 'AclService', 'Lightbox', 'Stock', 'StockStore', 'Dayend', 'Account', 'RouteList', 'ngAudio', '$translate', 'Voyage', 'UserManager', 'Announcement', 'Pallette',
    function ($scope, Setting, $location, $localStorage, $rootScope, User, $routeParams, $timeout, $window, $filter, socket, Order, FileUploader, $http, Worker, Customer, tourConfig, WizardHandler, AclService, Lightbox, Stock, StockStore, Dayend, Account, RouteList, ngAudio, $translate, Voyage, UserManager, Announcement, Pallette) {

        if (localStorage.getItem("lang")) {
            $translate.use(localStorage.getItem("lang"));
            $scope.mainLanguage = localStorage.getItem("lang");
        } else {
            $translate.use('tr');
            $scope.mainLanguage = 'tr';
        }

        $rootScope.realOrder = false;

        var lastType;
        var notLoad = ['/'];
        $scope.beepSound = ngAudio.load("sounds/beep.mp3");

        $scope.optimizedWorkers = [];
        $scope.optimizedWorkersID = [];
        $scope.optimizationSpinner = false;
        $rootScope.message = {
            'status': false,
            'text': '',
            'type': 'error'
        };

        $rootScope.dateTypes = [
            // {
            //     'name' : $translate.instant('CREATED_AT_TYPE'),
            //     'key': 'created_at'
            // },
            {
                'name': $translate.instant('CARGO_DATE_TYPE'),
                'key': 'cargo_date'
            },
            {
                'name': $translate.instant('COMPLETED_AT_TYPE'),
                'key': 'completed_at'
            },
            {
                'name': $translate.instant('LAST_LOG_DATE'),
                'key': 'last_log_date'
            },
            {
                'name': $translate.instant('DATA_CREATED_DATE'),
                'key': 'created_at'
            }
        ];

        $rootScope.termTypes = [
            {
                'name': 'Gönderi Kodu, Entegrasyon Kodu',
                'key': 'id'
            },
            {
                'name': 'Alıcı Adı ve Alıcı Soyadı',
                'key': 'location'
            },
            {
                'name': 'Alıcı Tel Numarası',
                'key': 'location_phone'
            },
            {
                'name': $translate.instant('3RD_NUMBER'),
                'key': '3rd_number'
            },
            {
                'name': 'Paket No',
                'key': 'package'
            },
            {
                'name': 'İrsaliye No',
                'key': 'special_value'
            },
            {
                'name': 'Sipariş No (Reference 1)',
                'key': 'reference_number_1'
            },
            {
                'name': 'Yurtdışı Takip Kodu',
                'key': 'shipmenthub'
            },
        ];

        $rootScope.marketPlaces = [
            {
                'name': $translate.instant('YES'),
                'key': 'yes'
            },
            {
                'name': $translate.instant('NO'),
                'key': 'no'
            },
        ];

        $rootScope.shipmenthubMarketplaces = [
            {
                'name': 'Amazon',
                'key': 'marketplace.amazon'
            },
            {
                'name': 'Otto',
                'key': 'marketplace.otto'
            },
            {
                'name': 'Trendyol',
                'key': 'trendyol'
            },
            {
                'name': 'Amazon	United Kingdom',
                'key': 'marketplace.amazon.co.uk'
            },
            {
                'name': 'Amazon	Belgium',
                'key': 'marketplace.amazon.com.b'
            },
            {
                'name': 'Amazon	Germany',
                'key': 'marketplace.amazon.de'
            },
            {
                'name': 'Amazon	Spain',
                'key': 'marketplace.amazon.es'
            },
            {
                'name': 'Amazon	France',
                'key': 'marketplace.amazon.fr'
            },
            {
                'name': 'Amazon	Italy',
                'key': 'marketplace.amazon.it'
            },
            {
                'name': 'Amazon	Netherlands',
                'key': 'marketplace.amazon.nl'
            },
            {
                'name': 'Amazon	Poland',
                'key': 'marketplace.amazon.pl'
            },
            {
                'name': 'Amazon	Sweden',
                'key': 'marketplace.amazon.se'
            },
            {
                'name': 'Otto Germany',
                'key': 'marketplace.otto.de'
            },
        ];

        $rootScope.shipmenthubCargos = [
            {
                'name': 'DHL',
                'key': 'DHL'
            },
            {
                'name': 'DPD',
                'key': 'DPD'
            },
            {
                'name': 'EVRI',
                'key': 'EVRI'
            },
            {
                'name': 'GLS',
                'key': 'GLS'
            },
            {
                'name': 'FEDEX',
                'key': 'FEDEX'
            },
        ];

        $rootScope.yesNo = [
            {
                'name': $translate.instant('YES'),
                'key': 'true'
            },
            {
                'name': $translate.instant('NO'),
                'key': 'false'
            },
        ];

        $rootScope.deliveryTypes = [
            {
                'name': 'Teslimat',
                'key': 'delivery'
            },
            {
                'name': 'İade',
                'key': 'return'
            },
        ];

        $rootScope.returnDeliveryTypes = [
            {
                'name': 'Kapıdan',
                'key': 'return_door'
            },
            {
                'name': 'Şubeden',
                'key': 'return_station'
            },
        ];

        $rootScope.thirdReads = [
            {
                'name': 'Yapıldı',
                'key': 'yes'
            },
            {
                'name': 'Yapılmadı',
                'key': 'no'
            },
        ];

        $rootScope.estimatedDeliveryDateFilters = [
            {
                'name': 'Gecikmiş Kargolar',
                'key': 'yes'
            },
            {
                'name': 'Gecikmemiş Kargolar',
                'key': 'no'
            },
            {
                'name': 'Kargo Yakın Takip (Çözülmeyen)',
                'key': 'unresolved_support'
            },
            {
                'name': 'Kargo Yakın Takip (Çözülen)',
                'key': 'resolved_support'
            },
        ];

        $rootScope.quotas = [
            {
                'name': $translate.instant('YES'),
                'key': 'yes'
            },
            {
                'name': $translate.instant('NO'),
                'key': 'no'
            },
        ];

        $rootScope.marketplaces = [
            {
                'value': 'trendyol',
                'text': 'TRENDYOL'
            }
        ]

        $rootScope.wrongTypes = [
            // {
            //     'name': 'Paket Transfer Merkezine İnmedi',
            //     'key': 'not_land_transfer'
            // },

            {
                'name': 'Tahmini Teslimatı Geçen',
                'key': 'late_order',
            },
            // {
            //     'name': 'Paket Transfer Merkezinden Çıkmadı',
            //     'key': 'not_exit_transfer'
            // },
            {
                'name': 'Paket Şubeye İnmedi',
                'key': 'not_delivered_to_station'
            },
            {
                'name': 'Kayıp Kargo',
                'key': 'lost_order'
            },
            {
                'name': 'Araştırma Süreci',
                'key': 'research_process'
            },
            // {
            //     'name': 'Paket Ring Aracından Şubeye İnmedi',
            //     'key': 'not_land_station'
            // },
            {
                'name': 'Paket Yanlış Şubeye İndi',
                'key': 'wrong_station'
            },
            {
                'name': 'Paket Dağıtıma Çıkmadı',
                'key': 'not_delivery'
            },
            {
                'name': 'Paket Kuryede Kaldı',
                'key': 'late_delivery'
            },
            // {
            //     'name': 'Paket Kuryede Kaldı 2',
            //     'key': 'late_delivery_20'
            // },
            // {
            //     'name': 'Müşteri Kodu Girilmedi',
            //     'key': 'customer_code_not_found'
            // },

        ];

        $rootScope.reportOrderTypes = [{
            'name': $translate.instant('OPEN_ORDERS'),
            'key': 'open'
        },
        {
            'name': $translate.instant('COMPLETED_ORDERS'),
            'key': 'completed'
        },
        {
            'name': $translate.instant('ALL_ORDERS_TYPE'),
            'key': 'all'
        },
        {
            'name': $translate.instant('REPORT_BY_STATUS'),
            'key': 'by_status'
        },
        {
            'name': $translate.instant('TOTAL_ORDER'),
            'key': 'total'
        },
        {
            'name': $translate.instant('STATIONS'),
            'key': 'stations'
        },
        {
            'name': $translate.instant('PARTNER'),
            'key': 'customer'
        },
        {
            'name': $translate.instant('AVR_CARGO'),
            'key': 'avr_order'
        },
        {
            'name': $translate.instant('WORKER_TARGET_REPORT'),
            'key': 'worker_target_report'
        },
        ];

        $rootScope.performanceTypes = [
            // {
            //     'name': 'İl',
            //     'key': 'city'
            // },
            {
                'name': $translate.instant('STATION'),
                'key': 'station'
            },
            {
                'name': $translate.instant('PARTNER'),
                'key': 'partner'
            },
            // {
            //     'name': $translate.instant('WORKER'),
            //     'key': 'worker'
            // }
        ];

        $rootScope.reportDateTypes = [{
            'name': $translate.instant('ESTIMATED_DELIVERY_DATE'),
            'key': 'estimated_delivery_date'
        },
        {
            'name': $translate.instant('CARGO_DATE'),
            'key': 'cargo_date'
        },
        {
            'name': $translate.instant('CUTOFF_DATE'),
            'key': 'cutoff_date'
        },
        ];

        $rootScope.statusFilter = []
        $rootScope.reportStationTypes = function () {
            Setting.getWorkSteps().success(function (data) {
                var fixedSteps = data.fixed_steps
                var list = fixedSteps.concat(data.steps);
                $rootScope.statusFilter = list
            })
        }

        $rootScope.orderMovementTypes = [{
            'name': $translate.instant('LOAD_VEHICLE'),
            'key': 'load_vehicle'
        },
        {
            'name': $translate.instant('LOAD_LINE_VEHICLE'),
            'key': 'load_line_vehicle'
        },
        {
            'name': $translate.instant('SET_DOWN'),
            'key': 'set_down'
        },
        {
            'name': $translate.instant('LOAD_STATION'),
            'key': 'load_station'
        },
        {
            'name': $translate.instant('DOWN_STATION'),
            'key': 'down_station'
        },
        {
            'name': $translate.instant('GROUND_CARGO'),
            'key': 'ground_cargo'
        },
        ];

        $scope.voyageMovementTypes = [{
            name: 'Müşteriden Teslim Al',
            key: 'load_vehicle'
        },
        {
            name: 'Transfer Merkezine İndir',
            key: 'set_down'
        },
        {
            name: 'Hat Aracına Yükle',
            key: 'load_line_vehicle'
        },
        {
            name: 'Hat Aracından İndir',
            key: 'down_line_vehicle'
        },
        {
            name: 'Teyit Okutması Yap',
            key: 'ground_cargo'
        },
        {
            name: 'Grup barkodu',
            key: 'group_cargo'
        },
        ];

        $scope.voyageStatuses = [{
            name: $translate.instant('OPEN'),
            key: 'new'
        },
        {
            name: $translate.instant('VOYAGE_ON_WAY'),
            key: 'on_way'
        },
        {
            name: $translate.instant('VOYAGE_COMPLETED'),
            key: 'completed'
        },
        ];

        $rootScope.supportStatuses = [{
            'name': $translate.instant('RESOLVED'),
            'id': 'resolved'
        },
        {
            'name': $translate.instant('UNRESOLVED'),
            'id': 'unresolved'
        }
        ];


        $rootScope.integrationList = [{
            'name': 'MNG',
            'id': 'mng'
        },
        {
            'name': 'Kargom Sende',
            'id': 'kargom_sende'
        },
        {
            'name': 'Sürat Kargo',
            'id': 'surat_kargo'
        },
        {
            'name': 'Sendeo',
            'id': 'sendeo'
        },
        {
            'name': 'PTT',
            'id': 'ptt'
        },
        {
            'name': 'MNG MP',
            'id': 'mng'
        },
        {
            'name': 'YURTICI',
            'id': 'yurtici'
        },
        {
            'name': 'Ticimax',
            'id': 'ticimax'
        },
        {
            'name': 'GelAl',
            'id': 'gelal_kargo'
        },
        {
            'name': 'Scotty',
            'id': 'scotty'
        },
        {
            'name': 'Gkn Kargo',
            'id': 'gkn_kargo'
        },
        ];

        $rootScope.cancelReasons = [{
            'name': 'Sorunlu Kargo Verisi',
            'id': 'Sorunlu Kargo Verisi'
        },
        {
            'name': 'Gönderici İptali',
            'id': 'Gönderici İptali'
        },
        {
            'name': 'Toplama İptali-Müşteri Kaynaklı',
            'id': 'Toplama İptali-Müşteri Kaynaklı'
        },
        {
            'name': 'Toplama İptali-Farklı Firma Tercihi',
            'id': 'Toplama İptali-Farklı Firma Tercihi'
        },
        {
            'name': 'Toplama İptali-Alıcıya Ulaşılamadı',
            'id': 'Toplama İptali-Alıcıya Ulaşılamadı'
        },
        ];

        $rootScope.permissions = [{
            'name': $translate.instant('DELETE_ORDER_PERMISSION'),
            'key': 'delete_order'
        },
        {
            'name': $translate.instant('SHOW_ROUTE_PERMISSION'),
            'key': 'show_route'
        },
        {
            'name': $translate.instant('SHOW_CUSTOMER_PERMISSION'),
            'key': 'show_customer'
        },
        {
            'name': $translate.instant('SHOW_ORDER_PERMISSION'),
            'key': 'show_order'
        },
        {
            'name': $translate.instant('ADD_USER_PERMISSION'),
            'key': 'add_user'
        },
        {
            'name': $translate.instant('SHOW_FINANCE_PERMISSION'),
            'key': 'show_finance'
        },
        {
            'name': $translate.instant('SHOW_SETTING_PERMISSION'),
            'key': 'show_setting'
        },
        {
            'name': $translate.instant('EXCEL_PERMISSION'),
            'key': 'excel_permission'
        },
        {
            'name': $translate.instant('CREATE_ORDER_PERMISSION'),
            'key': 'create_order_permission'
        },
        {
            'name': $translate.instant('CREATE_PRODUCT_PERMISSION'),
            'key': 'create_product_permission'
        },
        {
            'name': $translate.instant('COMPLETE_ROUTE_PERMISSION'),
            'key': 'complete_route_permission'
        },
        {
            'name': $translate.instant('DELETE_ROUTE_PERMISSION'),
            'key': 'delete_route_permission'
        },
        {
            'name': $translate.instant('ADD_WORKER_PERMISSION'),
            'key': 'add_worker_permission'
        },
        {
            'name': $translate.instant('ADD_CUSTOMER_PERMISSION'),
            'key': 'add_customer_permission'
        }
        ];

        $scope.paymentTypes = [{
            name: $translate.instant('DEBT'),
            id: 0
        },
        {
            name: $translate.instant('CREDIT'),
            id: 1
        }
        ];

        $scope.workerStatuses = [{
            name: 'Aktif',
            id: 'active'
        },
        {
            name: 'Pasif',
            id: 'passive'
        }
        ];

        $scope.compensateTypes = [
            { 'name': 'Tam Tazmin Fatura', code: 'full' },
            { 'name': 'Kısmi Tazmin Fatura', code: 'partial' }
        ];

        $scope.orderPaymentTypes = [{
            name: $translate.instant('ONLINE_PAYMENT_ORDER'),
            value: 'online'
        },
        {
            name: $translate.instant('OFFLINE_PAYMENT_ORDER'),
            value: 'offline'
        },
        {
            name: $translate.instant('UA_PAYMENT_TYPE'),
            value: 'buyer_pay'
        }
        ];

        $scope.thirdPartyDecisionPermitteds = ['sema.karahanli@bovo.com.tr', 'mertcan@bovo.com.tr', 'furkan@bovo.com.tr', 'ebrar.yildirim@bovo.com.tr', 'ibrahim.dogan@bovo.com.tr', 'admin@bovo.com.tr'];
        $scope.integrations = [{
            name: 'KargoTürk',
            value: 'postman'
        }];

        $scope.pageSizes = [
            25,
            50,
            100
        ];

        var customerAuthRoutes = [
            'customer-new-order',
            'customer-new-order',
            'customer-orders',
            'customer-users',
            'customer-dashboard',
            'customer-account',
            'customer-settings',
            'customer-password',
            'customer-tracking',
            'customer-accounting',
            'customer-billing',
            'customer-feedback',
            'customer-check-orders',
            'customer-notifications',
            'customer-support',
            'customer-consensus',
            'customer-new-order?return=true',
            'satin-al',
        ];
        var guest = ['/login', '/register', '/forgot', '/sifre-sifirla'];
        $scope.pageViewLogin = guest.indexOf($location.path()) > -1 ? true : false;

        $rootScope.$on('loading:progress', function () {
            if (notLoad.indexOf(window.location.pathname) == -1) {
                $scope.loadingEffect = true;
            }
        });

        $rootScope.$on('loading:finish', function () {
            if (notLoad.indexOf(window.location.pathname) == -1) {
                $scope.loadingEffect = false;
            }
        });

        $scope.$on('$routeChangeStart', function ($event, next, current) {
            if (typeof $scope.authenticatedUser != 'undefined' && $scope.authenticatedUser.role == 3) {
                var path = $location.url().split("/")[1];
                if (customerAuthRoutes.indexOf(path.split("?")[0]) == -1) {
                    $location.path("/customer-orders");
                    return;
                }
            }
        });

        $scope.newOrderData = {
            type: 1,
            address: {},
            images: [],
            assign: 'manual',
            notifications: [{
                type: 'push',
                time: 30,
                period: 'minute'
            }],
            faults: [{
                stock: '',
                description: ''
            }],
            checklists: [],
            scheduled_time: moment().add(1, 'hours').startOf('hour').format('DD/MM/YYYY hh:mm')
        };


        var ranges = {};
        ranges[$translate.instant("TODAY")] = [moment(), moment()];
        ranges[$translate.instant("YESTERDAY")] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        ranges[$translate.instant("LAST_7_DAYS")] = [moment().subtract(6, 'days'), moment()];
        ranges[$translate.instant("LAST_30_DAYS")] = [moment().subtract(29, 'days'), moment()];
        ranges[$translate.instant("THIS_MONTH")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[$translate.instant("LAST_MONTH")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

        $scope.opts = {
            autoApply: false,
            locale: {
                "format": "DD/MM/YYYY",
                "separator": " - ",
                "applyLabel": "Uygula",
                "cancelLabel": "Vazgeç",
                "fromLabel": "Dan",
                "toLabel": "a",
                "customRangeLabel": "Seç",
                "daysOfWeek": [
                    "Pz",
                    "Pt",
                    "Sl",
                    "Çr",
                    "Pr",
                    "Cm",
                    "Ct"
                ],
                "monthNames": [
                    "Ocak",
                    "Şubat",
                    "Mart",
                    "Nisan",
                    "Mayıs",
                    "Haziran",
                    "Temmuz",
                    "Ağustos",
                    "Eylül",
                    "Ekim",
                    "Kasım",
                    "Aralık"
                ],
                "firstDay": 1
            },
            ranges: ranges
        }

        $scope.copyİsVisibility = false

        $scope.copyİsVisibilityControl = function () {
            if ($scope.copyİsVisibility) {
                return true
            } else {
                false
            }
        }

        $scope.copyOrderDetail = function (id, sender, buyer, phone, address, city, district, location) {
            var copyText = `Kargo No: ${id} \n Gönderici: ${sender} \n Alıcı: ${buyer} \n Telefon Numarası: ${phone} \n Adres: ${address} ${location.building_number ? "Bina/Blok: " + location.building_number : ""}  ${location.apartment_number ? "Daire: " + location.apartment_number : ""} ${location.zipcode ? "Posta Kodu: " + location.zipcode : ""} \n İl/İlçe: ${city}/${district}`

            navigator.clipboard.writeText(copyText).then((result) => {
                alert('Kopyalandı')
            })
        }

        $scope.showStatus = function (status) {
            var selected = $filter('filter')($scope.statuses, { value: status });
            return (status && selected.length) ? selected[0].text : 'Not set';
        };

        var componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            administrative_area_level_2: 'short_name',
            country: 'long_name',
            postal_code: 'short_name'
        };

        var mapping = {
            street_number: 'number',
            route: 'street',
            locality: 'state',
            administrative_area_level_1: 'city',
            administrative_area_level_2: 'district',
            country: 'country',
            postal_code: 'zip'
        };

        $scope.colorList = [
            '#FF9800',
            '#3F51B5',
            '#2196F3',
            '#673AB7',
            '#009688',
            '#795548',
            '#607D8B',
            '#F44336'
        ];

        $rootScope.states = [];

        $scope.translate = function (key) {
            return $translate(key);
            return $translate(key).then(function (headline) {
                console.log(headline)
                return headline;
            }, function (translationId) {
                // $scope.headline = translationId;
            });
        };

        $scope.statuses = [{
            name: $translate.instant('OPEN'),
            value: '',
            id: 0,
        },
        {
            name: $translate.instant('PAID_SUCCESS'),
            value: 'complete',
            id: 1,
        }
        ]

        $rootScope.numberWithCommas = function (num) {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }

        $scope.$on('IdleStart', function () {
            if ($scope.authenticatedUser.setting.idle) {
                // $('#idle').modal({
                //     backdrop: 'static',
                //     keyboard: false,
                //     show: true
                // });
            } else {
                location.reload();
            }
        });

        $scope.refreshPage = function () {
            location.reload();
        }

        $scope.searchCustomer = function ($select, type) {
            if (type != lastType) $scope.searchRes = [];
            if ($select.search) {
                return $http.get('api/find-customer', {
                    params: {
                        s: $select.search,
                        type: type
                    },
                    ignoreLoadingBar: true
                }).then(function (response) {
                    $scope.searchRes = response.data.customers;
                });
                lastType = type;
            };
        };

        $rootScope.filterCustomers = [];
        $scope.getCustomers = function () {
            $http.get('api/filter/customers', {
                ignoreLoadingBar: true
            }).then(function (response) {
                $rootScope.filterCustomers = response.data.customers;
            });
        };

        $rootScope.filterSubCustomers = [];
        $scope.getSubCustomers = function () {
            $http.get('api/filter/sub-customers', {
                ignoreLoadingBar: true
            }).then(function (response) {
                $rootScope.filterSubCustomers = response.data.customers;
            });
        };

        $rootScope.filterCustomerGroups = [];
        $scope.getCustomerGroups = function () {
            $http.get('api/filter/customer-groups', {
                ignoreLoadingBar: true
            }).then(function (response) {
                $rootScope.filterCustomerGroups = response.data.customer_groups;
            });
        };

        $rootScope.filterLineVehicles = [];
        $scope.getLineVehicles = function () {
            $http.get('api/filter/line-vehicles', {
                ignoreLoadingBar: true
            }).then(function (response) {
                $rootScope.filterLineVehicles = response.data.lineVehicles;
            });
        };

        $rootScope.filterWorkers = [];
        $scope.getWorkers = function () {
            $http.get('api/filter/workers', {
                ignoreLoadingBar: true
            }).then(function (response) {
                $rootScope.filterWorkers = response.data.workers;
            });
        };

        $rootScope.filterDealers = [];
        $scope.getDealers = function () {
            $http.get('api/filter/dealers', {
                ignoreLoadingBar: true
            }).then(function (response) {
                $rootScope.filterDealers = response.data.dealers;
            });
        };

        $rootScope.filterStations = [];
        $scope.getStations = function () {
            $http.get('api/filter/stations', {
                ignoreLoadingBar: true
            }).then(function (response) {
                $rootScope.filterStations = response.data.stations;
            });
        };

        $rootScope.filterMainStations = [];
        $scope.getMainStations = function () {
            $http.get('api/filter/stations?type=management', {
                ignoreLoadingBar: true
            }).then(function (response) {
                $rootScope.filterMainStations = response.data.stations;
            });
        };

        $rootScope.filterSupportTypes = [];
        $scope.getSupportTypes = function () {
            $http.get('api/filter/support-types', {
                ignoreLoadingBar: true
            }).then(function (response) {
                $rootScope.filterSupportTypes = response.data.support_types;
            });
        };

        $scope.getSupportTypes();
        $rootScope.filterTransferReasons = [];
        $scope.getTransferReasons = function (type) {
            $http.get('api/filter/transfer-reasons?type=' + type, {
                ignoreLoadingBar: true
            }).then(function (response) {
                $rootScope.filterTransferReasons = response.data.transferReasons;
            });
        };

        $scope.parseCustomDate = function(dateString) {
            return moment(dateString, 'YYYYMMDD').format('DD/MM/YYYY');
        };

        $rootScope.filterAllCities = [
            {
                "id": 0,
                "city_id": 0,
                "name": "İl Seçimi Yapınız",
            }
        ];
        $rootScope.filterAllDistricts = [
            {
                "id": 0,
                "city_id": 0,
                "name": "İl Seçimi Yapınız",
            }
        ];

        $scope.getAllCities = async function () {
            await $http.get('api/cities', {
                ignoreLoadingBar: true
            }).then(function (response) {
                $rootScope.filterAllCities = response.data.data;
            });
        };

        $scope.getAllDistricts = async function (city) {
            $rootScope.filterAllDistricts = [];
            var findCity = $rootScope.filterAllCities.find(i => i.id == city);
            if (findCity) {
                if (!$scope.newOrderData.location) {
                    $scope.newOrderData.location = {}
                }
                $scope.$broadcast('city_selected', findCity);
            }
            await $http.get('api/districts?city_id=' + city, {
                ignoreLoadingBar: true
            }).then(function (response) {
                $rootScope.filterAllDistricts = response.data.data;
            });
        };

        $scope.onChangeDistrict = async function (district) {
            var findDistricy = $rootScope.filterAllDistricts.find(i => i.id == district);
            if (findDistricy) {
                $scope.$broadcast('district_selected', findDistricy);
            }
        };

        $rootScope.filterCities = [];
        $rootScope.filterDistricts = [];

        $scope.getCities = async function () {
            await $http.get('api/filter/cities', {
                ignoreLoadingBar: true
            }).then(function (response) {
                $rootScope.filterCities = response.data.cities;
                if ($scope.authenticatedUser.permission.name == 'dealer') {
                    $rootScope.filterDistricts = $rootScope.filterCities[0].districts;
                }
            });
        };

        $scope.getDistricts = function (city) {
            console.log(city)
            $rootScope.filterDistricts = [];
            city.forEach(cItem => {
                var find = $rootScope.filterCities.findIndex(function (item) {
                    return cItem == item.id;
                })
                if (find > -1) {
                    $rootScope.filterCities[find].districts.forEach(element => {
                        $rootScope.filterDistricts.push(element);
                    });
                }
            });
        };



        $scope.transferReasonList = []

        $scope.listControl = function (params) {
            var checkList = params.indexOf('19')
            if (checkList > -1) {
                transfer.style.display = 'block'
            }
        }


        $scope.listTransferReason = function () {
            $http.get('api/filter/transfer-reasons', {
                ignoreLoadingBar: true
            }).then(function (response) {
                $scope.transferReasonList = response.data.transferReasons
            });
        }

        $rootScope.changeSupportType = function (support_type_id, order) {
            $http.post('api/change-supports', { order_id: order.id, support_type_id: support_type_id }).then(function (response) {
                $scope.$broadcast('update_support', response);
                Messenger().post('Başarılı');
                order.support = true;
            });
        };

        $rootScope.changeSupportStatus = function (status, order_id) {
            $http.post('api/change-support-status', { order_id: order_id, status: status }).then(function (response) {
                $scope.$broadcast('update_support', response);
                Messenger().post('Başarılı');
            });
        };

        $scope.createWebhook = function () {
            Account.createWebhook({ customer_id: $scope.customer.id }).success(function (data, status, headers, config) {
                Messenger().post(data.message);
                console.log(data);
                $scope.customer.user.webhook = data.token.access_token;
            }).error(function (data) {
                Messenger().post({ message: data.message, type: "error" });
            })
        };

        function removeURLParameters(url, parameters) {
            parameters.forEach(function (element) {
                url = url
                    .replace(new RegExp('[?&]' + element + '=[^&#]*(#.*)?$'), '$1')
                    .replace(new RegExp('([?&])' + element + '=[^&]*&'), '$1');
            });
            return url;
        }

        $scope.init = function (user) {
            User.getUser().success(function (data, status, headers, config) {
                AclService.flushStorage();
                var abilities = {
                    user: []
                };
                AclService.setAbilities(abilities);
                AclService.attachRole('user');
                $scope.can = AclService.can;
                $scope.authenticatedUser = data;

                if (data.authorization.maps) AclService.addAbility('user', 'maps');
                if (data.authorization.office) AclService.addAbility('user', 'office');
                if (data.authorization.customers) AclService.addAbility('user', 'customers');
                if (data.authorization.users) AclService.addAbility('user', 'users');
                if (data.authorization.settings) AclService.addAbility('user', 'settings');
                if (data.authorization.reports) AclService.addAbility('user', 'reports');
                if (data.role == 3) {
                    AclService.addAbility('user', 'customer')
                };
                $rootScope.selectedTeamName = data.teams[0];
                $rootScope.firstLoad = true;

                if (data.role != 3 && data.announcements.length) {
                    Swal.fire({
                        title: data.announcements[0].title,
                        html: data.announcements[0].content,
                        icon: "info",
                        confirmButtonText: `
                        <i class="fa fa-thumbs-up"></i> Tamam
                      `,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Announcement.seen({ announcement_id: data.announcements[0].id })
                        } else if (result.isDenied) {
                            Swal.fire("Changes are not saved", "", "info");
                        }
                    });
                }

                if (typeof $scope.authenticatedUser != 'undefined' && $scope.authenticatedUser.role == 3) {
                    var path = $location.url().split("/")[1];
                    if (customerAuthRoutes.indexOf(removeURLParameters(path, ['data', 'real_order'])) == -1) {
                        $location.path("/customer-orders");
                        return;
                    }
                }

                Account.districts({ city_id: 34 }).success(function (data, status, headers, config) {
                    $rootScope.states = data.data
                })

                /* Tawk_API.visitor = {
                    name  : $scope.authenticatedUser.company_name
                }; */
            });
        };

        function changeFavicon(src) {
            var link = document.createElement('link'),
                oldLink = document.getElementById('dynamic-favicon');
            link.id = 'dynamic-favicon';
            link.rel = 'shortcut icon';
            link.href = src;
            if (oldLink) {
                document.head.removeChild(oldLink);
            }
            document.head.appendChild(link);
        }

        $scope.firstLook = function () {
            User.firstLook({ step: 2 }).success(function (data, status, headers, config) {
                $('#firstLook').modal('hide');
            });
        }

        $scope.compensate = {
            documents: [],
            step: 1
        }

        $scope.getCompensate = function (order) {
        }

        $scope.chekcCompensateDisabled = function (name, compensate) {
            if ($scope.authenticatedUser.permission.name == 'super_admin') {
                return false;
            }

            if (compensate.step > 1) {
                if (name == 'company' || name == 'station_id') {
                    return true;
                }
            }

            if (compensate.step > 2) {
                if (name == 'sended_approve') {
                    return true;
                }
            }

            if (compensate.step > 3) {
                if (name == 'approved' || name == 'approved_person') {
                    return true;
                }
            }

            if (compensate.step > 4) {
                if (name == 'invoice_sended' || name == 'invoice_number' || name == 'invoice_date') {
                    return true;
                }
            }

            if (compensate.step > 5) {
                if (name == 'customer_invoice_sent' || name == 'customer_invoice_number' || name == 'customer_invoice_date') {
                    return true;
                }
            }

            if (compensate.step > 6) {
                if (name == 'invoice_sended') {
                    return true;
                }
            }
        }

        $scope.postCompensate = function (compensate) {
            Swal.fire({
                title: 'Bu işlemi yapmak istediğinizden emin misiniz ?',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Evet',
                denyButtonText: 'Vazgeç',
                customClass: {
                    actions: 'my-actions',
                    cancelButton: 'order-1 right-gap',
                    confirmButton: 'order-2',
                    denyButton: 'order-3',
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    Order.compensate(compensate).success(function (data) {
                        $scope.compensate = data;
                        Swal.fire({
                            title: 'Başarıyla eklendi',
                            confirmButtonText: `
                            <i class="fa fa-thumbs-up"></i> Tamam
                          `,
                        })
                    }).error(function (data) {
                        Messenger().post({ message: data.message, type: "error" })
                    });
                }
            })

        }

        $scope.updates = function () {
            User.updates().success(function (data, status, headers, config) {
                $('#updates').modal('hide');
            });
        }

        $scope.mainPage = function () {
            if ($scope.authenticatedUser.role == 3) {
                $location.path('customer-orders')
            } else {
                $location.path('orders')
            }
            /*
            User.getUser().success(function(data, status, headers, config) {
                if(data.authorization.maps) $location.path('').search('type', null);
                else if(data.authorization.office) $location.path('projeler').search('type', null);
                else if(data.authorization.users) $location.path('calisan-yonetimi').search('type', null);
                else if(data.authorization.customers) $location.path('musteri-yonetimi').search('type', null);
                else if(data.authorization.reports) $location.path('order-report').search('type', null);
                else if(data.authorization.settings) $location.path('profile').search('type', null);
            });
            */
        }

        $scope.newWorkerLink = function () {
            $('#newOrder').modal('hide');
            setTimeout(function () {
                $location.path('yeni-calisan');
                $scope.$apply();
            }, 300);
        }

        $scope.selectedTeam = function (team) {
            $rootScope.selectedTeamName = team;
            $scope.$broadcast('teamChange', team);
        }

        socket.on('worker_answer', function (data) {
            $scope.$broadcast('worker_answer', data);
        });

        socket.on('location_update', function (data) {
            $scope.$broadcast('location_update', data);
        });

        socket.on('delete_order', function (data) {
            $scope.$broadcast('delete_order', data);
        });

        socket.on('refresh_page', function (data) {
            window.location.reload();
        });

        socket.on('new_order', function (data) {
            $scope.$broadcast('new_order', data);
        });

        socket.on('update_order', function (data) {
            $scope.$broadcast('update_order', data);
        });

        socket.on('worker_status', function (data) {
            $scope.$broadcast('worker_status', data);
        });
        $rootScope.messageCount = 0;
        socket.on('new_message', function (data) {
            $scope.beepSound.play();
            $rootScope.messageCount += 1;
            $scope.$broadcast('new_message', data);
        });

        $scope.addOrderNotification = function () {
            if (typeof $scope.newOrderData.notifications === 'undefined') {
                $scope.newOrderData.notifications = [];
            }
            $scope.newOrderData.notifications.push({
                type: 'push',
                time: 30,
                period: 'minute'
            });
        }

        $scope.removeOrderNotification = function (index) {
            $scope.newOrderData.notifications.splice(index, 1);
        }

        $scope.newTaskModal = function (_callback) {
            Order.newOrderData().success(function (data) {
                $scope.orderData = data;
                $scope.newOrderData = {
                    type: 1,
                    address: {},
                    images: [],
                    assign: 'manual',
                    notifications: [{
                        type: 'push',
                        time: 30,
                        period: 'minute'
                    }],
                    faults: [{
                        stock: '',
                        description: ''
                    }],
                    checklists: [],
                    scheduled_time: moment().add(15 - (moment().format('mm') % 15), 'minutes').startOf('minute').format('DD/MM/YYYY hh:mm')
                };
                $('.new-work-screen').scrollTop(0);
                // Create Map
                createMap();
                $scope.addOrderLocation();
                jQuery.datetimepicker.setLocale('en');
                jQuery('#datetimepicker').datetimepicker({
                    mask: true,
                    format: 'd/m/Y H:i',
                    defaultDate: $scope.newOrderData.scheduled_time,
                    inline: true,
                    timepickerScrollbar: false,
                    allowTimes: [
                        "00:00", "00:15", "00:30", "00:45",
                        "01:00", "01:15", "01:30", "01:45",
                        "02:00", "02:15", "02:30", "02:45",
                        "03:00", "03:15", "03:30", "03:45",
                        "04:00", "04:15", "04:30", "04:45",
                        "05:00", "05:15", "05:30", "05:45",
                        "06:00", "06:15", "06:30", "06:45",
                        "07:00", "07:15", "07:30", "07:45",
                        "08:00", "08:15", "08:30", "08:45",
                        "09:00", "09:15", "09:30", "09:45",
                        "10:00", "10:15", "10:30", "10:45",
                        "11:00", "11:15", "11:30", "11:45",
                        "12:00", "12:15", "12:30", "12:45",
                        "13:00", "13:15", "13:30", "13:45",
                        "14:00", "14:15", "14:30", "14:45",
                        "15:00", "15:15", "15:30", "15:45",
                        "16:00", "16:15", "16:30", "16:45",
                        "17:00", "17:15", "17:30", "17:45",
                        "18:00", "18:15", "18:30", "18:45",
                        "19:00", "19:15", "19:30", "19:45",
                        "20:00", "20:15", "20:30", "20:45",
                        "21:00", "21:15", "21:30", "21:45",
                        "22:00", "22:15", "22:30", "22:45",
                        "23:00", "23:15", "23:30", "23:45"
                    ],
                    scrollMonth: false,
                    onChangeDateTime: function (dp, $input) {
                        $scope.newOrderData.scheduled_time = moment($input.val(), 'DD/MM/YYYY hh:mm').format('DD/MM/YYYY hh:mm');
                    }
                })
                    .datetimepicker('reset');
                if (typeof _callback !== 'undefined') _callback(data);
            });
        }

        $scope.updateOrderField = function (key, value, order) {
            if (!value) {
                alert('Boş değer girilemez!');
                return;
            }
            var formParam = {
                key: key,
                value: value,
                order_id: order.id,
            }
            Order.updateOrderField(formParam).success(function (data) {
                console.log(data);
                $scope.getOrderDetails(order);
            });
        }

        $scope.loadRoute = function () {
            $('#routeDetail').modal('show');
            $scope.routeMarkers = [];
            Order.loadRoute($scope.selectedWork.id).success(function (data) {

                var location = new google.maps.LatLng(Number($scope.selectedWork.customer.latitude), Number($scope.selectedWork.customer.longitude)),
                    bounds = new google.maps.LatLngBounds(),
                    route = [],
                    workerPath;

                $scope.historyMap = new google.maps.Map(document.getElementById('map-history'), {
                    zoom: 13,
                    center: location,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                });

                data.locations.forEach(function (element) {
                    var routeLocation = new google.maps.LatLng(Number(element.latitude), Number(element.longitude));
                    route.push(routeLocation);
                    var marker = new google.maps.Marker({
                        position: routeLocation,
                        map: $scope.historyMap
                    });
                    var infowindow = new google.maps.InfoWindow({
                        content: "<strong>Doğruluk : </strong> " + element.accuracy + ' Metre </br> <strong>Tarih : </strong>' + moment(element.created_at).format('DD/MM/YYYY hh:mm')
                    });
                    marker.addListener('click', function () {
                        infowindow.open($scope.historyMap, marker);
                    });
                    bounds.extend(routeLocation);
                    $scope.routeMarkers.push(marker);
                });
                var iconsetngs = {
                    path: google.maps.SymbolPath.FORWARD_OPEN_ARROW
                };

                workerPath = new google.maps.Polyline({
                    path: route,
                    geodesic: true,
                    strokeColor: '#024466',
                    strokeOpacity: 0.7,
                    strokeWeight: 2,
                    icons: [{
                        repeat: '140px', //CHANGE THIS VALUE TO CHANGE THE DISTANCE BETWEEN ARROWS
                        icon: iconsetngs,
                        offset: '100%'
                    }]
                });
                workerPath.setMap($scope.historyMap);
                $scope.historyMap.fitBounds(bounds);

                // Company Marker
                if ($scope.selectedWork.customer.latitude.length > 0) {
                    var routeLocation = new google.maps.LatLng(Number($scope.selectedWork.customer.latitude), Number($scope.selectedWork.customer.longitude));
                    var marker = new google.maps.Marker({
                        position: routeLocation,
                        map: $scope.historyMap,
                        icon: 'img/markers/location_guest.png'
                    });
                    var infowindow = new google.maps.InfoWindow({
                        content: "<strong>Firma Adı : </strong> " + $scope.selectedWork.customer.customer_name + '</br> <strong>Adres : </strong>' + $scope.selectedWork.customer.customer_name
                    });
                    marker.addListener('click', function () {
                        infowindow.open($scope.historyMap, marker);
                    });
                    infowindow.open($scope.historyMap, marker);
                    bounds.extend(routeLocation);
                    $scope.historyMap.fitBounds(bounds);
                }
            });
        }

        $scope.toggleMarkerVisible = function () {
            $scope.routeMarkers.forEach(function (element) {
                if (element.getVisible()) {
                    element.setVisible(false);
                } else {
                    element.setVisible(true);
                }
            });
            $scope.routeMarkerVisible = !$scope.routeMarkerVisible;
        }

        $scope.duplicateWork = function () {
            $scope.newTaskModal(function (data) {
                $scope.newOrderData = $rootScope.selectedWork;
                $scope.newOrderData.address = $rootScope.selectedWork.customer;
                $scope.addressSelected($scope.newOrderData.address, 'outsource');
                delete $scope.newOrderData.id;
                $scope.newOrderData.worker_id = [$scope.newOrderData.worker_id];
            });
        }

        $scope.updateWork = function (type) {
            if (type && type == 'customer') {
                $location.path('customer-new-order').search('order_id', $rootScope.selectedWork.id);
            } else {
                $location.path('new-order').search('order_id', $rootScope.selectedWork.id);
            }
        }

        $scope.checkEstimatedDeliveryDate = function (order) {
            if (order.current_status && order.current_status.value == 'complete') {
                return moment(order.estimated_delivery_date).isBefore(moment(order.delivered_at));
            }
            return moment(order.estimated_delivery_date).isBefore(moment());
        }

        // $scope.printOrder = function(id) {
        //     window.open('/print-order/'+id,'popUpWindow','height=300,width=445,left=100,top=100,resizable=no,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes');
        // }

        $scope.printOrder = function (id) {
            window.open('/print-order?order_id=' + id, '_blank');
            return false;

            var newwindow = window.open('/print-order/?order_id=' + id, 'Print Order', 'width=700, height=470');
            if (window.focus) {
                newwindow.focus()
            }
            return false;
        }

        $scope.printPdfOrder = function (id) {
            var newwindow = window.open('/print-pdf-order/' + id, 'Print Order', 'width=700, height=470');
            if (window.focus) {
                newwindow.focus()
            }
            return false;
        }

        $scope.printReturnOrder = function (id) {
            window.open('/print-order?order_id=' + id + '&type=fail', '_blank');
            return false;
        }

        $scope.printReturnCompleteOrder = function (id) {
            window.open('/print-order?order_id=' + id + '&type=return', '_blank');
            return false;
        }

        $scope.printWorker = function (id) {
            var newwindow = window.open('/print-worker/' + id, 'Print Worker', 'width=700, height=470');
            if (window.focus) {
                newwindow.focus()
            }
            return false;
        }

        $scope.openOptimize = function () {
            if ($scope.selectedRouteOrder.length == 0) {
                alert('Lütfen rotalama yapmak istediğiniz işleri seçiniz.');
                return true;
            }
            $scope.optimize = {
                optimizeBy: 1
            };
            $scope.optimize.workers = [];
            Order.getWorkersForOptimizing().success(function (data, status, headers, config) {
                $scope.optimizedWorkers = data;
                for (var i = 0; i < data.length; i++) {
                    $scope.optimize.workers.push(data[i]['id']);
                }
                $('#optimizeRoute').modal('show');
            })
                .error(function (data) {
                    Messenger().post({ message: data.message, type: "error" });
                });
        }


        $scope.optimizeStep = false;
        $scope.selectedRouteOrder = [];
        $scope.addSelectedOrderForOptimization = function (item) {
            if ($scope.selectedRouteOrder.indexOf(item) > -1) {
                $scope.selectedRouteOrder.splice($scope.selectedRouteOrder.indexOf(item), 1);
            } else {
                $scope.selectedRouteOrder.push(item);
            }
        }

        $scope.getCheckbox = function (value) {
            try {
                return JSON.parse(value);
            } catch (error) {
                return [];
            }
        }

        // $scope.newOrder = function (newWork) {
        //     // if(newWork) {
        //     /* if($scope.newOrderData.assign == 'manual' && typeof $scope.newOrderData.worker_id == 'undefined') {
        //         $scope.info = {
        //             title : "Dikkat",
        //             message : "Manuel iş atamalarında personel seçmeniz gerekmektedir.",
        //             class : 'danger'
        //         };
        //         $('#orderInfo').modal('show');
        //     }else { */
        //     Order.newOrder($scope.newOrderData).success(function (data, status, headers, config) {
        //         $('#newOrder').modal('hide');
        //         $scope.$broadcast('new_order', data);
        //         $scope.newOrderData = {
        //             type: 1,
        //             address: {},
        //             images: [],
        //             scheduled_time: moment().add(1, 'hours').startOf('hour').format('DD/MM/YYYY hh:mm')
        //         };
        //         if ($scope.orderFromTour) $('#tutorialModal').modal('show');
        //     }).error(function (data) {
        //         Messenger().post({ message: data.message, type: "error" });
        //     });
        //     /*    }
        //      }else {
        //         $scope.info = {
        //             title : "Dikkat",
        //             message : "Lütfen açıklama, müşteri, adres ve personel alanlarını doldurun.",
        //             class : 'danger'
        //         };
        //         $('#orderInfo').modal('show');
        //     } */
        // };

        $scope.assignWorker = function () {
            Order.assignWorker($rootScope.selectedWork).success(function (data, status, headers, config) {
                $rootScope.selectedWork = data;
                $scope.$broadcast('updateOrder', data);
                Messenger().post({ message: 'Başarılı', type: "success" });
                $('#assignWorker').modal('hide');
            });
        }

        $scope.changeText = null;

        $scope.changeWorkStatus = function () {
            var text = changeText.value
            var userStatus = $scope.authenticatedUser.permission.name
            if ($rootScope.selectedWork.status.value == 'fail') {
                if (!$rootScope.selectedWork.transfer_reason_id) {
                    alert('Lütfen devir sebebi seçiniz!');
                    return;
                }
            }
            if ($rootScope.selectedWork.status.value == 'cancelled') {
                if (!$rootScope.selectedWork.cancel_reason) {
                    alert('Lütfen iptal sebebi seçiniz!');
                    return;
                }
            }

            if (userStatus == 'zone_manager') {
                if (text == '') {
                    textControl.style.display = "block"
                } else {

                    $rootScope.selectedWork = { ...$rootScope.selectedWork, change: text }

                    Order.changeWorkStatus($rootScope.selectedWork).success(function (data, status, headers, config) {
                        changeText.value = ''
                        textControl.style.display = "none"
                        $rootScope.selectedWork = data;
                        $scope.$broadcast('updateOrder', data);
                        Messenger().post({ message: 'Başarılı', type: "success" });
                        $('#changeWorkStatus').modal('hide');
                    }).error(function (data) {
                        Messenger().post({
                            message: data.message,
                            type: "error"
                        });
                    })
                }
            } else {
                $rootScope.selectedWork = { ...$rootScope.selectedWork, change: text }

                Order.changeWorkStatus($rootScope.selectedWork).success(function (data, status, headers, config) {
                    changeText.value = ''
                    textControl.style.display = "none"
                    $rootScope.selectedWork = data;
                    $scope.$broadcast('updateOrder', data);
                    Messenger().post({ message: 'Başarılı', type: "success" });
                    $('#changeWorkStatus').modal('hide');
                }).error(function (data) {
                    Messenger().post({
                        message: data.message,
                        type: "error"
                    });
                })
            }
        }

        $scope.addressSelected = function (selected, type) {
            console.log(selected);
            if (typeof type === 'undefined') {
                if (typeof selected !== 'undefined') {
                    var customer = selected.originalObject;
                } else {
                    var customer = [];
                }
            } else {
                var customer = selected;
            }
            if (typeof customer !== 'undefined' && typeof customer.latitude !== 'undefined') {
                var location = new google.maps.LatLng(customer.latitude, customer.longitude);
                if (typeof $scope.searchMap !== 'undefined') {
                    createMap();
                }
                setTimeout(function () {
                    $scope.searchMap.setCenter(location);
                    $scope.marker.setDuration(1000);
                    $scope.marker.setEasing("swing");
                    $scope.marker.setPosition(location);
                    angular.merge($scope.newOrderData.address, customer);
                    $scope.newOrderData.address.city = customer.state;
                    $scope.newOrderData.address.state = customer.city;
                    $scope.newOrderData.address.address = customer.address;
                    $scope.newOrderData.address.zipcode = customer.zipcode;
                    $rootScope.states.forEach(element => {
                        if (element.abbreviation == customer.city) {
                            $scope.newOrderData.address.state = element.name;
                            return true;
                        }
                    });
                    $scope.$apply();
                }, 300);
            }

            if (typeof customer !== 'undefined' && typeof customer.fields_values !== 'undefined') {
                $scope.newOrderData.address.fields = {};
                angular.forEach(customer.fields_values, function (value, key) {
                    var val = value.value;
                    if (value.type == 'number') val = Number(value.value);
                    if (value.type == 'date') val = new Date(value.value);
                    $scope.newOrderData.address.fields[value.customer_field_id] = val;
                });
            }
        }

        $scope.locationSelected = function (selected, type) {
            if (typeof type === 'undefined') {
                if (typeof selected !== 'undefined') {
                    var customer = selected.originalObject;
                } else {
                    var customer = [];
                }
            } else {
                var customer = selected;
            }
            if (typeof customer !== 'undefined' && typeof customer.latitude !== 'undefined') {
                var location = new google.maps.LatLng(customer.latitude, customer.longitude);
                setTimeout(function () {
                    $scope.searchMap.setCenter(location);
                    $scope.locationMarker.setDuration(1000);
                    $scope.locationMarker.setEasing("swing");
                    $scope.locationMarker.setPosition(location);
                    angular.merge($scope.newOrderData.location, customer);
                    $scope.newOrderData.location.city = customer.state;
                    $scope.newOrderData.location.state = customer.city;
                    $scope.newOrderData.location.address = customer.address;
                    $scope.newOrderData.location.zipcode = customer.zipcode;
                    $scope.$apply();
                }, 300);
            }
        }

        $scope.addOrderLocation = function (data) {
            if (data) {
                $scope.newOrderData.location = data;
                var location = new google.maps.LatLng(Number(data.latitude), Number(data.longitude));
            } else {
                $scope.newOrderData.location = {};
                var location = new google.maps.LatLng(Number($scope.authenticatedUser.location.lat), Number($scope.authenticatedUser.location.lon));
            }
            var bounds = new google.maps.LatLngBounds();
            setTimeout(function () {
                $scope.locationMarker = new SlidingMarker({
                    position: location,
                    map: $scope.searchMap,
                    visible: true,
                    draggable: true,
                    icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'
                });
                $scope.locationMarker.addListener('dragend', function (event) {
                    var latlng = new google.maps.LatLng(event.latLng.lat(), event.latLng.lng());
                    var geocoder = new google.maps.Geocoder();
                    geocoder.geocode({ 'latLng': latlng }, function (results, status) {
                        if (status === google.maps.GeocoderStatus.OK) {
                            if (results[0]) {
                                var place = results[0];
                                var details = place.geometry && place.geometry.location ? {
                                    latitude: place.geometry.location.lat(),
                                    longitude: place.geometry.location.lng()
                                } : {};
                                for (var i = 0; i < place.address_components.length; i++) {
                                    var addressType = place.address_components[i].types[0];
                                    if (componentForm[addressType]) {
                                        var val = place.address_components[i][componentForm[addressType]];
                                        details[mapping[addressType]] = val;
                                    }
                                }
                                details.formatted = place.formatted_address;
                                details.placeId = place.place_id;
                                details.address = place.formatted_address;
                                angular.merge($scope.newOrderData.location, details);
                                $scope.newOrderData.location.city = details.state;
                                $scope.newOrderData.location.state = details.city;
                                $scope.newOrderData.location.address = (details.number ? details.number : '') + ' ' + (details.street ? details.street : '');
                                $scope.newOrderData.location.zipcode = details.zip;
                                $rootScope.states.forEach(element => {
                                    if (element.abbreviation == details.city) {
                                        $scope.newOrderData.location.state = element.name;
                                        return true;
                                    }
                                });
                                $scope.$apply();
                            }
                        }
                    });
                });
            }, 600);
        }

        $scope.removeOrderLocation = function () {
            $scope.locationMarker.setMap(null);
            delete $scope.newOrderData.location;
        }

        $scope.deleteWork = function () {
            Order.deleteWork($rootScope.selectedWork).success(function (data, status, headers, config) {
                $rootScope.selectedWork = data;
                $scope.$broadcast('deleteOrder', data);
                $('#deleteWork').modal('hide');
            });
        }

        $scope.deleteTask = function () {
            Order.deleteTask($rootScope.selectedWork).success(function (data, status, headers, config) {
                $rootScope.selectedWork = data;
                $scope.$broadcast('deleteTask', data);
                $('#deleteTask').modal('hide');
            });
        }

        $scope.back = function () {
            $location.path('orders');
        }

        $scope.getOrderDetails = function (work) {
            $rootScope.selectedWork = null;
            $rootScope.loadingWork = true;
            var id = work.id;
            if (typeof id == 'undefined') {
                var id = work;
            }
            Order.order(id).success(function (data, status, headers, config) {
                $rootScope.loadingWork = false;
                $rootScope.selectedWork = data.order;
                if ($rootScope.selectedWork.compensate) {
                    $scope.compensate = $rootScope.selectedWork.compensate;
                } else {
                    $scope.compensate = {
                        documents: [],
                        step: 1
                    }
                    $scope.compensate.order_id = $rootScope.selectedWork.id;
                }
                $scope.data = {
                    'steps': data.steps,
                    'workers': data.workers,
                    types: data.types
                }
                $scope.data.steps.unshift({
                    id: 0,
                    name: 'Veri Oluşturuldu'
                });
                $scope.addCallRecord = false;
                $scope.selectedWork.items_code.forEach(element => {
                    var typeNumber = 4;
                    var errorCorrectionLevel = 'L';
                    var qr = qrcode(typeNumber, errorCorrectionLevel);
                    if (element.code) {
                        qr.addData(element.code);
                        qr.make();
                        element.qr = qr.createDataURL(10);
                    }

                });

                $rootScope.supportTypeId = null;
                $rootScope.supportStatus = null;
                if ($scope.selectedWork.support) {
                    $rootScope.supportTypeId = parseInt($scope.selectedWork.support.support_type_id)
                    $rootScope.supportStatus = $scope.selectedWork.support.status.toString();
                }

                if (data.order.mng_cargo) {
                    $scope.getMngCargoLogs();
                }
                if (data.order.sendeo_order_map) {
                    $scope.getSendeoOrder();
                }
                if (data.order.ptt_order_map) {
                    $scope.getPttOrder();
                }
                if (data.order.mng_mp_order_map) {
                    $scope.getMngMpOrder();
                }
                if (data.order.yurtici_order_map) {
                    $scope.getYurticiOrder();
                }
                if (data.order.kargom_sende_order_map) {
                    $scope.getKargomSendeOrder();
                }
                if (data.order.mng_return_order_map) {
                    $scope.getMngReturnOrder();
                }
                if (data.order.gkn_kargo_order_map) {
                    $scope.getGknKargoOrder();
                }
                if (data.order.scotty_order_map) {
                    $scope.getScottyOrder();
                }
                if (data.order.yango_order_map) {
                    $scope.getYangoOrder();
                }
                if (data.order.talha_nakliyat_order_map) {
                    $scope.getTalhaNakliyatOrder();
                }
                if (data.order.ankara_kargo_order_map) {
                    $scope.getAnkaraKargoOrder();
                }
                if (data.order.kolay_gelsin_order_map) {
                    $scope.getKolayGelsinOrder();
                }
                if (data.order.dhl_order_map) {
                    $scope.getDhlOrder();
                }
                if (data.order.dhl_uk_return_order_map) {
                    $scope.getDhlUkReturnOrder();
                }
                if (data.order.dhl_eu_return_order_map) {
                    $scope.getDhlEuReturnOrder();
                }
                if (data.order.amazon_shipping_order_map) {
                    $scope.getAmazonShippingOrder();
                }
                if (data.order.kargo_turk_order_map) {
                    $scope.getKargoTurkOrder();
                }
                if (data.order.herodispatcher_order_map) {
                    $scope.getHerodispatcherOrder();
                }
                if (data.order.bolt_order_map) {
                    $scope.getBoltOrder();
                }
                if (data.order.hepsi_jet_order_map) {
                    $scope.getHepiJetOrder();
                }
                if (data.order.surat_order_map) {
                    $scope.getSuratOrder();
                }
                if (data.order.gel_al_order_map) {
                    $scope.getGelAlOrder();
                }

                $('#orderDetail').modal('show');
                setTimeout(() => {
                    $('#tabContent a[href="#workdetails"]').tab('show');

                    try {
                        jQuery.datetimepicker.setLocale('en');
                        jQuery('#datetimepickerCompensate').datetimepicker({
                            timepicker: false,
                            datepicker: true,
                            format: 'Y-m-d',
                            value: data.order.compensate && data.order.compensate.invoice_date ? moment(data.order.compensate.invoice_date).format('YYYY-MM-DD') : new Date(),
                            onChangeDateTime: function (dp, $input) {
                                $scope.compensate.invoice_date = moment($input.val()).format('YYYY-MM-DD');
                            }
                        })
                        jQuery('#datetimepickerCompensate1').datetimepicker({
                            timepicker: false,
                            datepicker: true,
                            format: 'Y-m-d',
                            value: data.order.compensate && data.order.compensate.customer_invoice_date ? moment(data.order.compensate.customer_invoice_date).format('YYYY-MM-DD') : new Date(),
                            onChangeDateTime: function (dp, $input) {
                                $scope.compensate.customer_invoice_date = moment($input.val()).format('YYYY-MM-DD');
                            }
                        })
                    } catch (error) {
                        console.log(error)
                    }

                    $scope.$apply()
                }, 50);
            });
        }

        $scope.getMngCargoLogs = function () {
            Order.getMngCargoLogs($scope.selectedWork.id).success(function (data, status, headers, config) {
                if (data && data.logs && data.logs.NewDataSet) {
                    if (Array.isArray(data.logs.NewDataSet.Table1)) {
                        $rootScope.selectedWork.cargoLogs = data.logs.NewDataSet.Table1;
                    } else {
                        $rootScope.selectedWork.cargoLogs = [];
                        $rootScope.selectedWork.cargoLogs.push(data.logs.NewDataSet.Table1);
                    }
                } else {
                    $rootScope.selectedWork.cargoLogs = [];
                }

                $rootScope.selectedWork.cargoLogs.forEach(element => {
                    element.date = moment(element.ISLEM_TARIH_SAAT, 'DD.MM.YYYY HH:mm:ss')
                });
                if (data && data.status && data.status.NewDataSet && data.status.NewDataSet.FaturaSiparisListesi) {
                    $rootScope.selectedWork.cargoStatus = data.status.NewDataSet.FaturaSiparisListesi;
                    if ($rootScope.selectedWork.cargoStatus.KARGO_STATU_ACIKLAMA) {
                        var KARGO_STATU_ACIKLAMA = $rootScope.selectedWork.cargoStatus.KARGO_STATU_ACIKLAMA;
                        var status = KARGO_STATU_ACIKLAMA.indexOf('] Teslim Edilemedi');
                        if (status > -1) {
                            var result = KARGO_STATU_ACIKLAMA.substring(1, 3);
                            $rootScope.selectedWork.cargoStatus.fail_reason = findFailReason(result);
                            $rootScope.selectedWork.cargoStatus.KARGO_STATU_ACIKLAMA = KARGO_STATU_ACIKLAMA.split(']')[1];
                        }
                    }
                } else if (data && data.status && data.status.NewDataSet && data.status.NewDataSet.Table1) {
                    $rootScope.selectedWork.cargoStatus = data.status.NewDataSet.Table1;
                    if ($rootScope.selectedWork.cargoStatus.KARGO_STATU_ACIKLAMA) {
                        var KARGO_STATU_ACIKLAMA = $rootScope.selectedWork.cargoStatus.KARGO_STATU_ACIKLAMA;
                        var status = KARGO_STATU_ACIKLAMA.indexOf('] Teslim Edilemedi');
                        if (status > -1) {
                            var result = KARGO_STATU_ACIKLAMA.substring(1, 3);
                            $rootScope.selectedWork.cargoStatus.fail_reason = findFailReason(result);
                            $rootScope.selectedWork.cargoStatus.KARGO_STATU_ACIKLAMA = KARGO_STATU_ACIKLAMA.split(']')[1];
                        }
                    }
                } else {
                    $rootScope.selectedWork.cargoStatus = [];
                }
            });
        }

        $scope.getSendeoOrder = function () {
            Order.getSendeoOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.sendeoCargo = data;
            });
        }

        $scope.getPttOrder = function () {
            Order.getPttOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.pttCargo = data;
            });
        }

        $scope.getMngMpOrder = function () {
            Order.getMngMpOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.mngMpCargo = data;
            });
        }

        $scope.getYurticiOrder = function () {
            Order.getYurticiOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.yurticiCargo = data;
            });
        }

        $scope.getKargomSendeOrder = function () {
            Order.getKargomSendeOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.kargomSendeCargo = data;
            });
        }

        $scope.getMngReturnOrder = function () {
            Order.getMngReturnOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.mngReturnCargo = data;
            });
        }

        $scope.getGknKargoOrder = function () {
            Order.getGknKargoOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.gknKargoCargo = data;
            });
        }

        $scope.getScottyOrder = function () {
            Order.getScottyOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.scottyCargo = data;
            });
        }

        $scope.getYangoOrder = function () {
            Order.getYangoOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.yangoCargo = data;
            });
        }

        $scope.getTalhaNakliyatOrder = function () {
            Order.getTalhaNakliyatOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.talhaNakliyatCargo = data;
            });
        }

        $scope.getAnkaraKargoOrder = function () {
            Order.getAnkaraKargoOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.ankaraKargo = data;
            });
        }

        $scope.getKolayGelsinOrder = function () {
            Order.getKolayGelsinOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.kolayGelsinCargo = data;
            });
        }

        $scope.getDhlOrder = function () {
            Order.getDhlOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.dhlCargo = data;
            });
        }

        $scope.getDhlUkReturnOrder = function () {
            Order.getDhlUkReturnOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.dhlUkReturnCargo = data;
            });
        }

        $scope.getDhlEuReturnOrder = function () {
            Order.getDhlEuReturnOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.dhlEuReturnCargo = data;
            });
        }

        $scope.getAmazonShippingOrder = function () {
            Order.getAmazonShippingOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.amazonShippingCargo = data;
            });
        }

        $scope.getKargoTurkOrder = function () {
            Order.getKargoTurkOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.kargoTurkCargo = data;
            });
        }

        $scope.getHerodispatcherOrder = function () {
            Order.getHerodispatcherOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.herodispatcherCargo = data;
            });
        }

        $scope.getBoltOrder = function () {
            Order.getBoltOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.boltCargo = data;
            });
        }

        $scope.getHepiJetOrder = function () {
            Order.getHepiJetOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.hepsiJetCargo = data.real;
                $rootScope.selectedWork.hepsiJetCargoCache = data.cache;
            });
        }

        $scope.getSuratOrder = function () {
            Order.getSuratOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                console.log(data)
                $rootScope.selectedWork.suratCargo = data;
            });
        }

        $scope.getGelAlOrder = function () {
            Order.getGelAlOrder($scope.selectedWork.id).success(function (data, status, headers, config) {
                $rootScope.selectedWork.gelAlCargo = data;
            });
        }

        $scope.changeVariable = function (a, b) {
            $scope[a] = b;
        }

        $scope.getWorkerDetails = function (id) {
            $rootScope.loadingWork = true;
            Worker.show(id).success(function (data, status, headers, config) {
                $scope.worker = data.worker;
                $scope.teams = data.teams;
                $rootScope.loadingWork = false;
                $('#workerDetail').modal('show');
            });
        }

        $scope.deleteDocument = function (id, order) {
            if (confirm('Bu dokümanı silmek istediğinize emin misiniz ?')) {
                Order.deleteDocument({ document_id: id }).success(function (data, status, headers, config) {
                    $scope.getOrderDetails(order);
                    Messenger().post({ message: 'Doküman silinmiştir.', type: "success" });
                }).error(function (data) {
                    Messenger().post({ message: data.message, type: "error" });
                })
            }
        };

        $scope.chageDocumentField = function (key, id, value) {
            if (confirm('Bu işlemi yapmak istediğinize emin misiniz ?')) {
                Worker.chageDocumentField({ worker_id: $scope.worker.id, id: id, key: key, value: value }).success(function (data, status, headers, config) {
                    $scope.getWorkerDetails($scope.worker.id);
                }).error(function (data) {
                    Messenger().post({ message: data.message, type: "error" });
                })
            }
        };

        $scope.chageUserStatus = function (status) {
            if (confirm('Bu işlemi yapmak istediğinize emin misiniz ?')) {
                Worker.chageUserStatus({ worker_id: $scope.worker.id, status: status }).success(function (data, status, headers, config) {
                    $scope.getWorkerDetails($scope.worker.id);
                }).error(function (data) {
                    Messenger().post({ message: data.message, type: "error" });
                })
            }
        };

        $scope.hjRecall = function (id) {
            if (confirm('Bu işlemi yapmak istediğinize emin misiniz ?')) {
                Order.hjRecall({ order_id: id }).success(function (data, status, headers, config) {
                    alert(data.message);
                    $scope.getOrderDetails(id);
                }).error(function (data) {
                    Messenger().post({ message: data.message, type: "error" });
                })
            }
        };

        $scope.updateWorkerField = function (key, value) {
            if (confirm('Bu işlemi yapmak istediğinize emin misiniz ?')) {
                Worker.updateWorkerField({ worker_id: $scope.worker.id, key: key, value: value }).success(function (data, status, headers, config) {
                    $scope.getWorkerDetails($scope.worker.id);
                }).error(function (data) {
                    Messenger().post({ message: data.message, type: "error" });
                })
            }
        };

        $scope.chageWorkerStatus = function (status) {
            if (confirm('Bu işlemi yapmak istediğinize emin misiniz ?')) {
                Worker.chageWorkerStatus({ worker_id: $scope.worker.id, status: status }).success(function (data, status, headers, config) {
                    $scope.getWorkerDetails($scope.worker.id);
                }).error(function (data) {
                    Messenger().post({ message: data.message, type: "error" });
                })
            }
        };

        $scope.chageWorkerRouteStatus = function (status) {
            if (confirm('Bu işlemi yapmak istediğinize emin misiniz ?')) {
                Worker.chageWorkerRouteStatus({ worker_id: $scope.worker.id, status: status }).success(function (data, status, headers, config) {
                    $scope.getWorkerDetails($scope.worker.id);
                }).error(function (data) {
                    Messenger().post({ message: data.message, type: "error" });
                })
            }
        };

        $scope.openUserDetail = function (item, type = 'user') {
            UserManager.show(item.id).success(function (data, status, headers, config) {
                $('#userModal').modal('show');
                $scope.stations = data.stations;
                $scope.sytemUser = data.user;
                if (type != 'user') {
                    $scope.addUserCustomer = true;
                } else {
                    $scope.addUserCustomer = false;
                }
            });
        }


        $scope.assignAuth = function (type) {
            switch (type) {
                case 'super_admin':
                    $scope.sytemUser.owner = true;
                    $scope.sytemUser.permission = {
                        delete_order: true,
                        show_route: true,
                        show_customer: true,
                        show_order: true,
                        // show_palletes: true,
                        // show_sumary: true,
                        add_user: true,
                        show_finance: true,
                        show_setting: true,
                        excel_permission: true,
                        create_order_permission: true,
                        create_product_permission: true,
                        complete_route_permission: true,
                        delete_route_permission: true,
                        add_worker_permission: true,
                        add_customer_permission: true,
                        show_station: true,
                        dealer: false
                    }
                    break;
                case 'admin':
                    $scope.sytemUser.permission = {
                        delete_order: false,
                        show_route: true,
                        show_customer: true,
                        // show_sumary: true,
                        show_order: true,
                        add_user: false,
                        show_finance: true,
                        show_setting: false,
                        // show_palletes: true,
                        excel_permission: true,
                        create_order_permission: true,
                        create_product_permission: true,
                        complete_route_permission: true,
                        delete_route_permission: true,
                        add_worker_permission: true,
                        add_customer_permission: true,
                        show_station: true,
                        dealer: false
                    }
                    break;
                case 'ik':
                    $scope.sytemUser.permission = {
                        delete_order: false,
                        show_route: true,
                        show_customer: true,
                        // show_sumary: true,
                        show_order: true,
                        // show_palletes: false,
                        add_user: true,
                        show_finance: false,
                        show_setting: false,
                        excel_permission: false,
                        create_order_permission: false,
                        create_product_permission: false,
                        complete_route_permission: false,
                        delete_route_permission: false,
                        add_worker_permission: false,
                        add_customer_permission: false,
                        show_station: true,
                        dealer: false
                    }
                    break;
                case 'sales':
                    $scope.sytemUser.permission = {
                        delete_order: false,
                        show_route: true,
                        show_customer: true,
                        // show_sumary: true,
                        // show_palletes: false,
                        show_order: true,
                        add_user: false,
                        show_finance: false,
                        show_setting: false,
                        excel_permission: false,
                        create_order_permission: false,
                        create_product_permission: false,
                        complete_route_permission: false,
                        delete_route_permission: false,
                        add_worker_permission: false,
                        add_customer_permission: false,
                        show_station: true,
                        dealer: false
                    }
                    break;
                case 'dealer':
                    $scope.sytemUser.permission = {
                        delete_order: false,
                        show_route: true,
                        show_customer: true,
                        // show_sumary: true,
                        // show_palletes: false,
                        show_order: true,
                        add_user: false,
                        show_finance: true,
                        show_setting: false,
                        excel_permission: true,
                        create_order_permission: true,
                        create_product_permission: false,
                        complete_route_permission: true,
                        delete_route_permission: false,
                        add_worker_permission: false,
                        add_customer_permission: false,
                        show_station: true,
                        dealer: true
                    }
                    break;
                case 'zone_manager':
                    $scope.sytemUser.permission = {
                        delete_order: false,
                        show_route: true,
                        // show_palletes: false,
                        show_customer: true,
                        show_order: true,
                        // show_sumary: true,
                        add_user: false,
                        show_finance: true,
                        show_setting: false,
                        excel_permission: true,
                        create_order_permission: false,
                        create_product_permission: false,
                        complete_route_permission: true,
                        delete_route_permission: false,
                        add_worker_permission: true,
                        add_customer_permission: false,
                        show_station: true,
                        dealer: true
                    }
                    break;
                case 'support':
                    $scope.sytemUser.permission = {
                        delete_order: false,
                        show_route: true,
                        show_customer: true,
                        // show_palletes: false,
                        show_order: true,
                        add_user: false,
                        show_finance: false,
                        // show_sumary: true,
                        show_setting: false,
                        excel_permission: false,
                        create_order_permission: false,
                        create_product_permission: false,
                        complete_route_permission: false,
                        delete_route_permission: false,
                        add_worker_permission: false,
                        add_customer_permission: false,
                        show_station: true,
                        dealer: false
                    }
                    break;
                case 'supplier':
                    $scope.sytemUser.permission = {
                        delete_order: false,
                        show_route: true,
                        show_customer: false,
                        show_order: true,
                        add_user: true,
                        // show_palletes: false,
                        // show_sumary: true,
                        show_finance: false,
                        show_setting: false,
                        excel_permission: false,
                        create_order_permission: false,
                        create_product_permission: false,
                        complete_route_permission: false,
                        delete_route_permission: false,
                        add_worker_permission: false,
                        add_customer_permission: false,
                        show_station: false,
                        dealer: false,
                    }
                    break;
                default:
                    break;
            }
            $scope.sytemUser.permission.name = type;
        }

        $scope.addUser = function (type = 'user') {
            if (type != 'user') {
                $scope.addUserCustomer = true;
            }
            UserManager.newUser().success(function (data, status, headers, config) {
                $scope.stations = data.stations;
                $scope.sytemUser = {
                    company_customer_id: null,
                    authorization: {
                        maps: true,
                        office: true,
                        customers: true,
                        users: true,
                        reports: true,
                        settings: true
                    }
                }
                if (type == 'customer') {
                    $scope.sytemUser.company_customer_id = $scope.customer.id
                }
            });

            $('#userModal').modal('show');
        }

        $scope.postUserForm = function (user) {
            if ($scope.sytemUser && $scope.sytemUser.id) {
                $scope.updateUserCustomer();
            } else {
                $scope.saveUserCustomer();
            }
        }

        $scope.saveUserCustomer = function () {
            UserManager.saveUser($scope.sytemUser).success(function (data, status, headers, config) {
                Messenger().post(data.message);
                $('#userModal').modal('hide');
                if ($scope.addUserCustomer) {
                    $scope.getCustomerDetails($scope.customer.id)
                } else {
                    $scope.$broadcast('init_user');
                }
            }).error(function (data) {
                Messenger().post({
                    message: data.message,
                    type: "error"
                });
            });
        }

        $scope.updateUserCustomer = function () {
            UserManager.updateUser($scope.sytemUser).success(function (data, status, headers, config) {
                Messenger().post(data.message);
                $('#userModal').modal('hide');
                if ($scope.addUserCustomer) {
                    $scope.getCustomerDetails($scope.customer.id)
                } else {
                    $scope.$broadcast('init_user');
                }
            }).error(function (data) {
                Messenger().post({
                    message: data.message,
                    type: "error"
                });
            })
        }

        $scope.deleteUser = function (user) {
            var r = confirm("Bu kullanıcıyı silmek istediğinize emin misiniz ? Bu işlem geri alınamaz bir işlemdir!");
            if (r == true) {
                UserManager.deleteUser([user.id]).success(function (data, status, headers, config) {
                    $scope.getCustomerDetails($scope.customer.id)
                })
            }
        }

        $scope.getCustomerDetails = function (id) {
            $rootScope.loadingWork = true;
            Customer.show(id).success(function (data, status, headers, config) {
                $scope.customer = data.customer;
                $scope.customer.users = data.users;

                $scope.chart = data.chart;
                $scope.fields = data.fields;
                $scope.workTypes = data.workTypes;
                $rootScope.loadingWork = false;
                $('#customerDetail').modal('show');
                return;
                $scope.map = new google.maps.Map(document.getElementById('map-customer'), {
                    zoom: 15,
                    disableDefaultUI: true,
                    scrollwheel: false,
                    navigationControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    draggable: false,
                    center: new google.maps.LatLng(Number(data.customer.latitude), Number(data.customer.longitude)),
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                });
                $scope.marker = new SlidingMarker({
                    position: new google.maps.LatLng(Number(data.customer.latitude), Number(data.customer.longitude)),
                    map: $scope.map,
                    visible: true,
                    draggable: true
                });
            });
        }

        $scope.getStockDetail = function (id) {
            $rootScope.loadingWork = true;
            Stock.get(id).success(function (data, status, headers, config) {
                $scope.data = data;
                $rootScope.loadingWork = false;
                $('#stockDetail').modal('show');
            });
        }

        $scope.getDayendDetail = function (id) {
            $rootScope.loadingWork = true;
            Dayend.get(id).success(function (data, status, headers, config) {
                $scope.data = data;
                $rootScope.loadingWork = false;
                $('#dayendDetail').modal('show');
            });
        }

        $scope.getStockStoreDetail = function (id) {
            $rootScope.loadingWork = true;
            StockStore.get(id).success(function (data, status, headers, config) {
                $scope.data = data;
                $rootScope.loadingWork = false;
                $('#stockStoreDetail').modal('show');
            });
        }

        $scope.$on('$routeChangeStart', function (next, current) {
            $('.modal').modal('hide');
        });

        $scope.inputType = 'password';
        // Hide & show password function
        $scope.hideShowPassword = function () {
            if ($scope.inputType == 'password')
                $scope.inputType = 'text';
            else
                $scope.inputType = 'password';
        };
        $scope.inputTypeArr = [
            'password',
            'password',
            'password'
        ];
        $scope.hideShowPasswordArr = function (index) {
            if ($scope.inputTypeArr[index] == 'password')
                $scope.inputTypeArr[index] = 'text';
            else
                $scope.inputTypeArr[index] = 'password';
        };

        $scope.login = function (e) {
            User.login(e).success(function (data, status, headers, config) {
                if (data.route == '/admin') {
                    location.reload();
                    return;
                }
                $scope.authenticatedUser = data.data;
                AclService.flushStorage();
                var abilities = {
                    user: []
                };
                AclService.setAbilities(abilities);
                AclService.attachRole('user');
                $scope.can = AclService.can;

                socket.connect(data.stoken);

                if (data.authorization.maps) AclService.addAbility('user', 'maps');
                if (data.authorization.office) AclService.addAbility('user', 'office');
                if (data.authorization.customers) AclService.addAbility('user', 'customers');
                if (data.authorization.users) AclService.addAbility('user', 'users');
                if (data.authorization.settings) AclService.addAbility('user', 'settings');
                if (data.authorization.reports) AclService.addAbility('user', 'reports');
                if (data.role == 3) AclService.addAbility('user', 'customer');

                location.href = data.route;
                return;

            }).error(function (data) {
                Messenger().post({ message: $translate.instant(data.message), type: "error" });
            });
        };

        $scope.register = function (status, outOfRegion) {
            if (!status) {
                $scope.registerNext();
                return true;
            }
            $scope.registerLoading = true;
            if ($routeParams.platform) {
                $scope.registerForm.platform = $routeParams.platform;
            }
            User.register($scope.registerForm).success(function (data, status, headers, config) {
                $scope.registerLoading = false;
                window.localStorage.removeItem('registeredDriver')

                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    console.log('mobile')
                    try {
                        webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify(data));
                    } catch (error) {
                        console.log(error);
                    }
                    // window.open('bovodriver://loginasuser?data='+ JSON.stringify(data), '_system')
                    $scope.registerStep = 7;
                } else {
                    $scope.registerStep = 7;
                }

                // if($routeParams.platform && $routeParams.platform !== 'null') {
                // }else {
                // }
            }).error(function (data) {
                Messenger().post({ message: data.message, type: "error" });
                $scope.registerLoading = false;
            });
        };

        $scope.nextDisabled = function () {
            if ($scope.registerStep == 1) {
                if (
                    $scope.registerForm.first_name && $scope.registerForm.first_name.length > 0 &&
                    $scope.registerForm.last_name && $scope.registerForm.last_name.length > 0
                ) {
                    return true;
                }
            }
            if ($scope.registerStep == 2) {
                if (
                    $scope.registerForm.address && $scope.registerForm.address.length > 0 &&
                    $scope.registerForm.city && $scope.registerForm.city.length > 0 &&
                    $scope.registerForm.state && $scope.registerForm.state.length > 0 &&
                    $scope.registerForm.zipcode && $scope.registerForm.zipcode.length > 0
                ) {
                    return true;
                }
            }
            if ($scope.registerStep == 3) {
                if (
                    $scope.registerForm.vehicle_type && $scope.registerForm.vehicle_type.length > 0 &&
                    $scope.registerForm.vehicle_year && $scope.registerForm.vehicle_year.length > 0 &&
                    $scope.registerForm.vehicle_make && $scope.registerForm.vehicle_make.length > 0 &&
                    $scope.registerForm.vehicle_model && $scope.registerForm.vehicle_model.length > 0
                ) {
                    return true;
                } else {
                    $scope.showErrorMessage = true;
                }
            }
            if ($scope.registerStep == 4) {
                if (
                    $scope.registerForm.license_number && $scope.registerForm.license_number.length > 0 &&
                    $scope.registerForm.birthday_month && $scope.registerForm.birthday_month.length > 0 &&
                    $scope.registerForm.birthday_day && $scope.registerForm.birthday_day.length > 0 &&
                    $scope.registerForm.birthday_year && $scope.registerForm.birthday_year.length > 0 &&
                    $scope.registerForm.ssn_number && $scope.registerForm.ssn_number.length > 0 &&
                    $scope.registerForm.bgc_status
                ) {
                    return true;
                }
            } else if ($scope.registerStep == 5) {
                if ($scope.docusignStatus) {
                    return true;
                }
            }
            if ($scope.registerStep == 6) {
                if (
                    $scope.registerForm.account_name && $scope.registerForm.account_name.length > 0 &&
                    $scope.registerForm.routing_number && $scope.registerForm.routing_number.length > 0 &&
                    $scope.registerForm.account_number && $scope.registerForm.account_number.length > 0
                ) {
                    return true;
                    $scope.register(true);
                }
            }

            return false;
        };

        $scope.registerStep = 1;
        $scope.registerNext = function () {
            if ($scope.registerStep == 1) {
                if (
                    $scope.registerForm.first_name && $scope.registerForm.first_name.length > 0 &&
                    $scope.registerForm.last_name && $scope.registerForm.last_name.length > 0
                ) {
                    $scope.registerStep = 2;
                }
            }
            if ($scope.registerStep == 2) {
                if (
                    $scope.registerForm.address && $scope.registerForm.address.length > 0 &&
                    $scope.registerForm.city && $scope.registerForm.city.length > 0 &&
                    $scope.registerForm.state && $scope.registerForm.state.length > 0 &&
                    $scope.registerForm.zipcode && $scope.registerForm.zipcode.length > 0
                ) {
                    if ($scope.registerForm.state != 'NY' && $scope.registerForm.state != 'NJ') {
                        $scope.registerLoading = true;
                        $scope.registerForm.outOfRegion = true;
                        User.register($scope.registerForm).success(function (data, status, headers, config) {
                            $scope.successBoxMessage = "We are currently operating NY and NJ only. We will notify you when we start operating in your area.";
                            $('#successBox').modal('show');
                        }).error(function (data) {
                            Messenger().post({ message: data.message, type: "error" });
                            $scope.registerLoading = false;
                        });
                    } else {
                        $scope.registerStep = 3;
                    }
                }
            }
            if ($scope.registerStep == 3) {
                if (
                    $scope.registerForm.vehicle_type && $scope.registerForm.vehicle_type.length > 0 &&
                    $scope.registerForm.vehicle_year && $scope.registerForm.vehicle_year.length > 0 &&
                    $scope.registerForm.vehicle_make && $scope.registerForm.vehicle_make.length > 0 &&
                    $scope.registerForm.vehicle_model && $scope.registerForm.vehicle_model.length > 0
                ) {
                    $scope.registerStep = 4;
                } else {
                    $scope.showErrorMessage = true;
                }
            }
            if ($scope.registerStep == 4) {
                if (
                    $scope.registerForm.license_number && $scope.registerForm.license_number.length > 0 &&
                    $scope.registerForm.birthday_month && $scope.registerForm.birthday_month.length > 0 &&
                    $scope.registerForm.birthday_day && $scope.registerForm.birthday_day.length > 0 &&
                    $scope.registerForm.birthday_year && $scope.registerForm.birthday_year.length > 0 &&
                    $scope.registerForm.ssn_number && $scope.registerForm.ssn_number.length > 0
                ) {
                    $scope.registerStep = 5;
                }
            } else if ($scope.registerStep == 5) {
                if ($scope.docusignStatus) {
                    $scope.registerStep = 6;
                } else {
                    Messenger().post({ message: 'Please Sign W-9 Form', type: "error" });
                }
            }
            if ($scope.registerStep == 6) {
                if (
                    $scope.registerForm.account_name && $scope.registerForm.account_name.length > 0 &&
                    $scope.registerForm.routing_number && $scope.registerForm.routing_number.length > 0 &&
                    $scope.registerForm.account_number && $scope.registerForm.account_number.length > 0
                ) {
                    $scope.register(true);
                }
            }
        };
        $scope.docusignStatus = false;
        $scope.openDocuSign = function () {
            window.localStorage.setItem('registeredDriver', JSON.stringify($scope.registerForm));
            User.setStorage({ key: 'registeredDriver', value: $scope.registerForm }).success(function (data, status, headers, config) {
                // Drivername - businessname - Driverstreet - Driverapt - Driverstate - Driverzipcode - driverssn
                var url = 'https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=7e962e4f-c510-4be8-9fc7-8715f7721e14&env=na3-eu1&Driver_UserName=';
                url = url + $scope.registerForm.first_name + ' ' + $scope.registerForm.last_name;
                url = url + '&Driver_Email=' + $scope.registerForm.email;
                url = url + '&Drivername=' + $scope.registerForm.first_name + ' ' + $scope.registerForm.last_name;
                url = url + '&Drivercity=' + $scope.registerForm.city;
                url = url + '&Driverstreet=' + $scope.registerForm.address;
                url = url + '&Driverapt=' + $scope.registerForm.address2;
                url = url + '&Driverstate=' + $scope.registerForm.state;
                url = url + '&Driverzipcode=' + $scope.registerForm.zipcode;
                url = url + '&driverssn=' + $scope.registerForm.ssn_number;
                $scope.docusignUrl = url;
                window.location.href = url;
            })

            // $scope.docusignInterval = setInterval(() => {
            //     $scope.completeDocusign();
            // }, 3000);
        }

        $scope.completeDocusign = function () {
            User.completeDocusign({ email: $scope.registerForm.email }).success(function (data, status, headers, config) {
                $scope.docusignStatus = true;
                $scope.registerStep = 6;
                clearInterval($scope.docusignInterval);
                $('#docuSignModal').modal('hide')
            }).error(function (data) {
                // Messenger().post({message: data.message,type: "error"});
            });
        }

        $scope.registerBack = function () {
            $scope.registerStep -= 1;
        }


        $scope.registerInit = function (e) {

            // window.onbeforeunload = function (event) {
            //     var $openModalSelector = $(".modal.in");
            //     if( ($openModalSelector.data('bs.modal') || {}).isShown == true){
            //         $openModalSelector.modal("hide");
            //     }
            //     event.preventDefault();
            //     return 'Are you sure you want to leave? You are in the middle of something.';
            // };

            $scope.registerForm = {};
            if ($routeParams.ref) {
                $scope.registerForm.coming_reference_key = $routeParams.ref;
            }
            if ($routeParams.email) {
                $scope.registerForm.email = $routeParams.email;
            }
            if ($routeParams.phone_number) {
                $scope.registerForm.phone_number = $routeParams.phone_number;
            }
            if ($routeParams.password) {
                $scope.registerForm.password = $routeParams.password;
            }
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    $scope.registerForm.latitude = position.coords.latitude;
                    $scope.registerForm.longitude = position.coords.longitude;
                });
            }
            if ($routeParams.step) {
                $scope.registerStep = $routeParams.step;
                if ($scope.registerStep == 6) {
                    try {
                        var data = JSON.parse(window.localStorage.getItem('registeredDriver'));
                        // $scope.registerForm = data;
                        User.getStorage({ key: 'registeredDriver' }).success(function (data, status, headers, config) {
                            console.log(data)
                            $scope.registerForm = data;
                        })
                        if ($routeParams.docusign) {
                            $scope.registerStep = 6;
                        }
                    } catch (error) {
                        console.log(error)
                        console.log(window.localStorage.getItem('registeredDriver'))
                    }
                }
            }

            /* window.onbeforeunload = function () {
                return 'Are you sure you want to leave? You are in the middle of something.';
            }; */

            User.registerInit().success(function (data, status, headers, config) {
                if (data.email) {
                    $scope.registerForm.email = data.email;
                }
            }).error(function (data) {
                Messenger().post({ message: data.message, type: "error" });
            });
        };

        $scope.exportProof = function (status) {
            $scope.exportProofLoad = true;
            Order.exportProof($scope.selectedWork.id).success(function (data, status, headers, config) {
                $scope.exportProofLoad = false;
                window.location.href = data;
            }).error(function (data) {
                Messenger().post({ message: data.message, type: "error" });
                $scope.exportProofLoad = false;
            });
        };

        $scope.forgot = function (e) {
            User.forgot({ email: e }).success(function (data, status, headers, config) {
                Messenger().post("Mail Adresinize Sıfırlama Maili Gönderilmiştir.");
                setTimeout(function () { window.location = "/"; }, 2000);
            }).error(function (data) {
                Messenger().post({ message: data.message, type: "error" });
            });
        };

        $scope.filterWorkerFromTeam = function (item) {
            if ($scope.newOrderData.team_id !== null && typeof $scope.newOrderData.team_id !== 'undefined') {
                return item.team_id === $scope.newOrderData.team_id;
            } else {
                return item;
            }
        }

        $scope.password = function (e) {
            e.token = $routeParams.token;
            User.password(e).success(function (data, status, headers, config) {
                Messenger().post(data.message);
                $location.path('/login');
            }).error(function (data) {
                Messenger().post({ message: data.message, type: "error" });
            });
        };

        $rootScope.$on('unauthorized', function () {
            $scope.logout();
        });

        $rootScope.closeModal = function (modal) {
            $('#accountLimit').modal('hide');
            $('.modal-backdrop').remove();
            $('body').removeClass('modal-open');
        }

        $scope.logout = function () {
            window.location = '/cikis';
            delete $localStorage.token;
        };

        $scope.stringToArray = function (data) {
            return data.split(',');
        }

        $scope.toNumber = function (data) {
            return Number(data);
        }

        function clearMarker(type) {
            $scope.markers[type] = [];
        }

        $(document).on('click', '.dropdown-menu.dropdown-menu-form', function (e) {
            e.stopPropagation();
        });

        $scope.isActive = function (route) {
            var path = $location.url().split("/")[1];

            if (typeof route === 'string') {
                return route == path;
            }
            return route.indexOf(path) !== -1;
        };

        $scope.isInclude = function (route) {
            if (Array.isArray(route)) {
                var status = 0;
                route.forEach(function (item) {
                    if ($location.url().indexOf(item) > -1) {
                        status += 1;
                    }
                })
                return status > 0 ? true : false;
            } else {
                return $location.url().indexOf(route) > -1;
            }
        };


        $scope.isActiveCustomer = function (route) {
            var path = '';
            for (var index = 0; index < $location.url().split("/").length; index++) {
                path += $location.url().split("/")[index] + '/';
            }
            return route.indexOf(path) !== -1;
        };

        $scope.isActiveStock = function (route) {
            var path = '';
            for (var index = 0; index < $location.url().split("/").length; index++) {
                path += $location.url().split("/")[index] + '/';
            }
            return route.indexOf(path) > -1;
        };

        $scope.getDate = function (date) {
            return new Date(date);
        }

        $scope.print = function () {
            window.print();
        }

        function createMap() {
            var location = new google.maps.LatLng(Number($scope.authenticatedUser.location.lat), Number($scope.authenticatedUser.location.lon));

            setTimeout(function () {
                $scope.searchMap = new google.maps.Map(document.getElementById('map-order'), {
                    zoom: 9,
                    center: location,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                });

                $scope.marker = new SlidingMarker({
                    position: location,
                    map: $scope.searchMap,
                    visible: true,
                    draggable: true
                });

                var input = document.getElementById('googlePlaceInput');
                google.maps.event.addDomListener(input, 'keydown', function (e) {
                    if (e.keyCode == 13) {
                        e.preventDefault();
                    }
                });

                $scope.marker.addListener('dragend', function (event) {
                    var latlng = new google.maps.LatLng(event.latLng.lat(), event.latLng.lng());
                    var geocoder = new google.maps.Geocoder();
                    geocoder.geocode({ 'latLng': latlng }, function (results, status) {
                        if (status === google.maps.GeocoderStatus.OK) {
                            if (results[0]) {
                                var place = results[0];
                                var details = place.geometry && place.geometry.location ? {
                                    latitude: place.geometry.location.lat(),
                                    longitude: place.geometry.location.lng()
                                } : {};
                                for (var i = 0; i < place.address_components.length; i++) {
                                    var addressType = place.address_components[i].types[0];
                                    if (componentForm[addressType]) {
                                        var val = place.address_components[i][componentForm[addressType]];
                                        details[mapping[addressType]] = val;
                                    }
                                }
                                details.formatted = place.formatted_address;
                                details.placeId = place.place_id;
                                details.address = place.formatted_address;
                                angular.merge($scope.newOrderData.address, details);
                                $scope.newOrderData.address.city = details.state;
                                $scope.newOrderData.address.state = details.city;
                                $scope.newOrderData.address.address = (details.number ? details.number : '') + ' ' + (details.street ? details.street : '');
                                $scope.newOrderData.address.zipcode = details.zip;
                                $rootScope.states.forEach(element => {
                                    if (element.abbreviation == details.city) {
                                        $scope.newOrderData.address.state = element.name;
                                        return true;
                                    }
                                });
                                $scope.$apply();
                            }
                        }
                    });
                });
            }, 300);
        }

        var logo = $scope.logo = new FileUploader({
            url: '/api/upload-image-order',
            headers: { 'X-CSRF-TOKEN': CSRF_TOKEN },
            autoUpload: true
        });

        logo.filters.push({
            name: 'formatFilter',
            fn: function (item /*{File|FileLikeObject}*/, options) {
                var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                if ('|jpg|png|jpeg|bmp|gif|'.indexOf(type) === -1) {
                    alert('Sadece resim formatları desteklenmektedir. (jpg, png, jpeg, bmp, gif)');
                    return false;
                };
                return true;
            }
        });

        logo.onAfterAddingFile = function (fileItem) {
            $scope.progress = true;
            $scope.pending = true;
        };

        logo.onCompleteItem = function (item, response, status, headers) {
            $scope.progress = false;
            $scope.newOrderData.images.push(response.image);
            if (response.status == 'success') $scope.pending = false;
        }


        var document = $scope.document = new FileUploader({
            url: '/api/upload-document-worker',
            headers: { 'X-CSRF-TOKEN': CSRF_TOKEN },
            autoUpload: true
        });

        document.filters.push({
            name: 'formatFilter',
            fn: function (item /*{File|FileLikeObject}*/, options) {
                var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                if ('|jpg|png|jpeg|bmp|gif|pdf|doc|xlsx'.indexOf(type) === -1) {
                    alert('Sadece resim formatları desteklenmektedir. (jpg|png|jpeg|bmp|gif|pdf|doc|xlsx)');
                    return false;
                };
                return true;
            }
        });

        document.onAfterAddingFile = function (fileItem) {
            $scope.progress = true;
            $scope.pending = true;
        };

        document.onCompleteItem = function (item, response, status, headers) {
            $scope.progress = false;
            console.log(item)
            console.log(response)
            $scope.worker.company_document = response
        }

        $scope.deleteImgFromNewOrder = function (image) {
            var index = $scope.newOrderData.images.indexOf(image);
            $scope.newOrderData.images.splice(index, 1);
        }

        // import task list
        $scope.donwloadSampleExcel = function () {
            Order.donwloadSampleExcel().success(function (data, status, headers, config) {
                downloadURI(data.url, "Bovo Import Order.xlsx");
            });
        };

        function downloadURI(uri, name) {
            var link = document.createElement("a");
            link.download = name;
            link.href = uri;
            link.click();
        }

        $scope.importOrderList = function (files) {
            $scope.upload = true;
            var fd = new FormData();
            if ($rootScope.company_customer_id) {
                fd.append('company_customer_id', $rootScope.company_customer_id);
            }
            var imgBlob = dataURItoBlob(files);
            fd.append('file', imgBlob);
            $http.post('/api/import-order-list', fd, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined,
                    'X-CSRF-TOKEN': CSRF_TOKEN
                }
            })
                .success(function (response) {
                    // Messenger().post(response.message);
                    angular.element('#excelImportInput').val(null);
                    $('#importOrderList').modal('hide');
                    $rootScope.proccesCount = 2;
                    $('#successBox').modal('show');
                })
                .error(function (response) {
                    $rootScope.message = {
                        status: true,
                        type: 'error',
                        text: response.message
                    }
                    setTimeout(function () {
                        $rootScope.message.status = false;
                        $scope.$apply();
                    }, 3000);
                    // Messenger().post({message: response.message,type: "error"});
                })
                .finally(function (data) {
                    $scope.upload = false;
                });
        };

        $scope.importInvoiceList = function (files) {
            $scope.upload = true;
            var fd = new FormData();
            if ($rootScope.company_customer_id) {
                fd.append('company_customer_id', $rootScope.company_customer_id);
            }
            var imgBlob = dataURItoBlob(files);
            fd.append('file', imgBlob);
            $http.post('/api/import-order-invoices', fd, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined,
                    'X-CSRF-TOKEN': CSRF_TOKEN
                }
            })
                .success(function (response) {
                    // Messenger().post(response.message);
                    angular.element('#excelImportInput').val(null);
                    $('#importInvoiceList').modal('hide');
                    $rootScope.proccesCount = 2;
                    alert('Veriler kuyruğa alındı. Veri büyüklüğüne göre 10-30 dk içerisinde işlenerek tamamlanacaktır.')
                    location.reload()
                })
                .error(function (response) {
                    $rootScope.message = {
                        status: true,
                        type: 'error',
                        text: response.message
                    }
                    setTimeout(function () {
                        $rootScope.message.status = false;
                        $scope.$apply();
                    }, 3000);
                    // Messenger().post({message: response.message,type: "error"});
                })
                .finally(function (data) {
                    $scope.upload = false;
                });
        };

        $scope.importCompensate = function (files, files2, files3) {
            $scope.upload = true;
            var fd = new FormData();
            $rootScope.excelType = 1;
            if (typeof files !== 'undefined') {
                var imgBlob = dataURItoBlob(files);
            }

            if (typeof files2 !== 'undefined') {
                var imgBlob = dataURItoBlob(files2);
                $rootScope.excelType = 2;
            }

            if (typeof files3 !== 'undefined') {
                var imgBlob = dataURItoBlob(files3);
                $rootScope.excelType = 3;
            }

            fd.append('file', imgBlob);
            fd.append('excelType', $rootScope.excelType);
            $http.post('/api/import-order-compensate', fd, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined,
                    'X-CSRF-TOKEN': CSRF_TOKEN
                }
            })
                .success(function (response) {
                    angular.element('#excelImportInput').val(null);
                    $('#importInvoiceList').modal('hide');
                    $rootScope.proccesCount = 2;
                    alert('Veriler kuyruğa alınmıştır, bir kaç dk içerisinde tamamlanmış olacaktır.')
                    location.reload()
                })
                .error(function (response) {
                    $rootScope.message = {
                        status: true,
                        type: 'error',
                        text: response.message
                    }
                    setTimeout(function () {
                        $rootScope.message.status = false;
                        $scope.$apply();
                    }, 3000);
                    // Messenger().post({message: response.message,type: "error"});
                })
                .finally(function (data) {
                    $scope.upload = false;
                });

        };

        $scope.importDecisionList = function (files) {
            $scope.upload = true;
            var fd = new FormData();
            if ($rootScope.company_customer_id) {
                fd.append('company_customer_id', $rootScope.company_customer_id);
            }
            var imgBlob = dataURItoBlob(files);
            fd.append('file', imgBlob);
            $http.post('/api/import-third-party-decisions', fd, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined,
                    'X-CSRF-TOKEN': CSRF_TOKEN
                }
            })
                .success(function (response) {
                    // Messenger().post(response.message);
                    angular.element('#excelImportInput').val(null);
                    $('#importDecisionList').modal('hide');
                    $rootScope.proccesCount = 2;
                    alert('Yükleme Tamamlandı')
                    location.reload()
                })
                .error(function (response) {
                    $rootScope.message = {
                        status: true,
                        type: 'error',
                        text: response.message
                    }
                    setTimeout(function () {
                        $rootScope.message.status = false;
                        $scope.$apply();
                    }, 3000);
                    // Messenger().post({message: response.message,type: "error"});
                })
                .finally(function (data) {
                    $scope.upload = false;
                });
        };

        $scope.importOffZoneList = function (files) {
            $scope.upload = true;
            var fd = new FormData();
            if ($rootScope.company_customer_id) {
                fd.append('company_customer_id', $rootScope.company_customer_id);
            }
            var imgBlob = dataURItoBlob(files);
            fd.append('file', imgBlob);
            $http.post('/api/import-off-zones', fd, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined,
                    'X-CSRF-TOKEN': CSRF_TOKEN
                }
            })
                .success(function (response) {
                    // Messenger().post(response.message);
                    angular.element('#excelImportInput').val(null);
                    $('#importOffZoneList').modal('hide');
                    $rootScope.proccesCount = 2;
                    alert('Yükleme Tamamlandı')
                    // location.reload()
                })
                .error(function (response) {
                    $rootScope.message = {
                        status: true,
                        type: 'error',
                        text: response.message
                    }
                    setTimeout(function () {
                        $rootScope.message.status = false;
                        $scope.$apply();
                    }, 3000);
                    // Messenger().post({message: response.message,type: "error"});
                })
                .finally(function (data) {
                    $scope.upload = false;
                });
        };

        $scope.sortKolayGelsin = function (date) {
            return -moment(date.TimeStamp, 'DD.MM.YYYY HH:mm:ss');
        };

        $scope.getKGTransaction = function (item) {
            var transactions = [
                {
                    'code': 1,
                    'name': 'Gönderi Kayıt'
                },
                {
                    'code': 2,
                    'name': 'Toplama MOYsi Ayrıldı'
                },
                {
                    'code': 3,
                    'name': '“Toplama MOYsi Yaklaştı.”'
                },
                {
                    'code': 6,
                    'name': 'Gönderen randevu girdi'
                },
                {
                    'code': 7,
                    'name': 'Gönderen arandı'
                },
                {
                    'code': 8,
                    'name': 'Satıcı Adreste Bulunamadı'
                },
                {
                    'code': 10,
                    'name': '“Gönderen adresi güncellendi”'
                },
                {
                    'code': 11,
                    'name': 'Toplama iptal edildi'
                },
                {
                    'code': 12,
                    'name': 'Toplama yapıldı'
                },
                {
                    'code': 13,
                    'name': 'Toplama Transfer Merkezinde indirildi'
                },
                {
                    'code': 14,
                    'name': 'Dağıtım Transfer Merkezinde indirildi'
                },
                {
                    'code': 15,
                    'name': 'Anahat aracına yüklendi'
                },
                {
                    'code': 17,
                    'name': 'MOY Aracına Yüklendi'
                },
                {
                    'code': 18,
                    'name': 'Teslim MOY u ayrıldı'
                },
                {
                    'code': 19,
                    'name': 'Müşteri operasyon yetkilimiz teslimat için size yaklaştı.'
                },
                {
                    'code': 22,
                    'name': 'Alıcı randevu girdi'
                },
                {
                    'code': 23,
                    'name': 'Alıcı komşuya götür isteği yaptı'
                },
                {
                    'code': 24,
                    'name': 'Alıcı arandı'
                },
                {
                    'code': 26,
                    'name': 'Alıcı adreste bulunamadı'
                },
                {
                    'code': 27,
                    'name': 'Alıcı adresini güncelledi'
                },
                {
                    'code': 28,
                    'name': 'Alıcı teslimi reddetti'
                },
                {
                    'code': 29,
                    'name': 'Teslim yapıldı'
                },
                {
                    'code': 31,
                    'name': 'Gönderi durduruldu'
                },
                {
                    'code': 32,
                    'name': 'İade'
                },
                {
                    'code': 33,
                    'name': 'Kayıp'
                },
                {
                    'code': 34,
                    'name': 'Gönderi adresi zenginleştirildi'
                },
                {
                    'code': 35,
                    'name': 'Adres problemli olarak bildirildi'
                },
                {
                    'code': 36,
                    'name': 'Problemli olarak bildirilen adres düzeltildi'
                },
                {
                    'code': 39,
                    'name': 'Görev ilgili MÇAnın ilk iş gününe ertelendi'
                },
                {
                    'code': 40,
                    'name': 'Gönderen bilgisi değişti.'
                },
                {
                    'code': 41,
                    'name': 'Alıcı bilgisi değişti'
                },
                {
                    'code': 43,
                    'name': 'İade edilmek üzere devir depoya aktarıldı.'
                },
                {
                    'code': 44,
                    'name': 'Tekrar dağıtıma çıkmak üzere devir depoya aktarıldı.'
                },
                {
                    'code': 45,
                    'name': 'Dağıtım için devir depodan çıkarıldı.'
                },
                {
                    'code': 46,
                    'name': 'Devir depodan iade edildi.'
                },
                {
                    'code': 51,
                    'name': 'Zimmet Bırakıldı'
                },
                {
                    'code': 53,
                    'name': 'AT Dışı iade edilecek'
                },
                {
                    'code': 54,
                    'name': 'Devir Depo İade Edilmek Üzere Bekliyor Bildirimi'
                },
                {
                    'code': 55,
                    'name': 'Devir Depo Yarın İade Edilmek Üzere Bekliyor Bildirimi'
                },
                {
                    'code': 58,
                    'name': 'Hasar Tutanağı oluşturuldu'
                },
                {
                    'code': 61,
                    'name': 'Aranan müşteriye ulaşılamadı.'
                },
                {
                    'code': 83,
                    'name': 'Hasardan dolayı iade edilecek.'
                },
                {
                    'code': 86,
                    'name': 'Gönderi Hazır Değil'
                },
                {
                    'code': 97,
                    'name': 'Toplama Başarısız'
                },
                {
                    'code': 99,
                    'name': 'İadenizi almak için tekrar geleceğiz.'
                },
                {
                    'code': 100,
                    'name': 'Gönderici İşyeri Kapalı'
                },
                {
                    'code': 101,
                    'name': 'Alıcı İşyeri Kapalı'
                },
            ]

            return transactions.find(i => i.code == item) ? transactions.find(i => i.code == item).name : '';
        };

        $scope.parseBoltReceived = function (item) {
            var transactions = [
                {
                    'code': 1,
                    'name': 'Kendisi'
                },
                {
                    'code': 1,
                    'name': 'Kendisi'
                },
                {
                    'code': 2,
                    'name': 'Aile Ferdi'
                },
                {
                    'code': 3,
                    'name': 'Çalışanı'
                },
                {
                    'code': 4,
                    'name': 'Arkadaşı'
                },
                {
                    'code': 5,
                    'name': 'Komşusu'
                },
                {
                    'code': 6,
                    'name': 'Apt. Görevlisi'
                },
                {
                    'code': 7,
                    'name': 'Güvenlik'
                },
                {
                    'code': 8,
                    'name': 'Diğer'
                }
            ]

            return transactions.find(i => i.code == item) ? transactions.find(i => i.code == item).name : '';
        };

        $scope.uploadme;

        function dataURItoBlob(dataURI) {
            var binary = atob(dataURI.split(',')[1]);
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var array = [];
            for (var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], {
                type: mimeString
            });
        }

        $scope.humanizeDuration = function (time) {
            return humanizeDuration(time, { largest: 2, round: true, serialComma: false, language: $scope.mainLanguage });
        }

        $scope.isHasCommentSupport = function (id) {

        }

        $rootScope.shareCustomer = false;
        $rootScope.sendOperation = false;
        $scope.sendComment = function (noteContent, shareCustomer, sendOperation) {
            if (typeof noteContent === 'undefined' || noteContent.length == 0) {
                alert('Not girmediniz!');
                return false;
            }
            $rootScope.loadingWork = true;
            var data = {
                description: noteContent,
                order_id: $rootScope.selectedWork.id,
                share_customer: shareCustomer
            }
            Order.sendComment(data).success(function (data, status, headers, config) {
                if (sendOperation) {
                    Order.infosetNotes(data).success(function (data, status, headers, config) {
                        console.log('infoset');
                    })
                }
                $rootScope.loadingWork = false;
                noteContent = '';
                $rootScope.selectedWork.logs.unshift(data);
                $('#noteInput').val('');
                $scope.scrollEnd('msg_history');
                $scope.noteContent = '';
                delete noteContent;
                $rootScope.shareCustomer = false;
                $rootScope.sendOperation = false;
                setTimeout(() => {
                    $scope.$apply();
                }, 300);
            });
            // Order.supportNotes($rootScope.selectedWork).success(function (data, status, headers, config) {
            //     $('.not').prop('checked', false);
            // })
            noteContent = null;
        }

        $scope.scrollEnd = function (id) {
            // var div = document.getElementById(id);
            // $('#' + id).animate({
            //     scrollTop: div.scrollHeight - div.clientHeight
            // }, 500);
        }

        $scope.sendSms = function (sms_content, to) {
            if (typeof sms_content === 'undefined' || sms_content.length == 0) {
                alert('Boş sms gönderilemez!');
                return false;
            }
            $rootScope.loadingWork = true;
            var data = {
                sms_content: sms_content,
                order_id: $rootScope.selectedWork.id,
                to: to
            }
            Order.sendSms(data).success(function (data, status, headers, config) {
                $rootScope.loadingWork = false;
                $('#smsContent').val('');
                $('#smsContent2').val('');
                Messenger().post(data.message);
            });
        }

        $scope.sendCallRecord = function (reason, to) {
            if (typeof reason === 'undefined' || reason.length == 0) {
                alert('Boş çağrı kaydı eklenemez!');
                return false;
            }
            $rootScope.loadingWork = true;
            var data = {
                reason: reason,
                order_id: $rootScope.selectedWork.id,
                to: to
            }
            Order.sendCallRecord(data).success(function (data, status, headers, config) {
                $rootScope.loadingWork = false;
                $('#callRecord').val('');
                $scope.addCallRecord = false;
                Messenger().post(data.message);
            });
        }

        $scope.sendCustomerNote = function (description, id) {
            if (typeof description === 'undefined' || description.length == 0) {
                alert('Please fill note field!');
                return false;
            }
            $rootScope.loadingWork = true;
            var data = {
                description: description,
                customer_id: id
            }
            Customer.sendNote(data).success(function (data, status, headers, config) {
                $rootScope.loadingWork = false;
                $scope.customer.notes.unshift(data);
                $('#noteInputCustomer').val('');
                description = '';
            });
        }

        $scope.deleteCustomer = function (customerId = null) {
            var r = confirm("Bu müşteriyi pasife almak istediğinize emin misiniz ?");
            if (r == true) {
                Customer.deleteCustomer([customerId]).success(function (data, status, headers, config) {
                    Messenger().post(data.message);
                    $scope.refreshData();
                }).error(function (data) {
                    Messenger().post({ message: data.message, type: "error" });
                });
            }
        };

        $scope.activeCustomer = function (customerId = null) {
            var r = confirm("Bu müşteriyi aktife almak istediğinize emin misiniz ?");
            if (r == true) {
                Customer.restoreCustomer({ customer_ids: [customerId] }).success(function (data, status, headers, config) {
                    Messenger().post(data.message);
                    $scope.refreshData();
                }).error(function (data) {
                    Messenger().post({ message: data.message, type: "error" });
                });
            }
        };

        $scope.openRoute = function (id, order_id) {
            var url = 'routes?route_id=' + id + '&term=' + order_id;
            window.open(url, '_blank');
        }

        $scope.toggleDays = function (item) {
            item.checked = !item.checked;
        }

        $scope.addReceiver = function () {
            if (typeof $scope.newOrderData.receivers === 'undefined') {
                $scope.newOrderData.receivers = [{
                    'phone_number': ''
                }];
            } else {
                $scope.newOrderData.receivers.push({
                    'phone_number': ''
                });
            }
        }

        $scope.removeReceiver = function (index) {
            $scope.newOrderData.receivers.splice(index, 1);
        }

        $scope.showImageDetails = function (index) {
            $scope.showImageDetail[index] = !$scope.showImageDetail[index];
        }

        $scope.toggleRepeat = function (bool) {
            if (!bool) {
                delete $scope.newOrderData.repeat;
            } else {
                $scope.newOrderData.repeat = {
                    period: 'day',
                    end: 0,
                    days: [{
                        name: 'Pt',
                        value: 'Pt',
                        checked: true
                    },
                    {
                        name: 'Sa',
                        value: 'Sa',
                        checked: true
                    },
                    {
                        name: 'Ça',
                        value: 'Ça',
                        checked: true
                    },
                    {
                        name: 'Pe',
                        value: 'Pe',
                        checked: true
                    },
                    {
                        name: 'Cu',
                        value: 'Cu',
                        checked: true
                    },
                    {
                        name: 'Ct',
                        value: 'Ct',
                        checked: true
                    },
                    {
                        name: 'Pz',
                        value: 'Pz',
                        checked: true
                    }
                    ]
                };
            }
        }

        $scope.goBack = function () {
            window.history.back();
        }

        $scope.intercom = function () {
            Intercom("boot", {
                app_id: "ojb4g6vy"
            });
            Intercom('show');
        }

        $scope.finishTour = function () {
            Order.tour({ completed: true });
            $('#tutorialModal').modal('hide');
            $location.path('/map');
            $scope.authenticatedUser.tour = true;
            tourConfig.backDrop = true;
            var curStep = 0;
            if (typeof curStep === 'string')
                curStep = parseInt(curStep);
            $scope.currentStep = curStep || 0;
            $('#tutorialModal').modal('hide');
            $scope.tour = 1;
            $scope.orderFromTour = false;
        }

        $scope.startCreateOrder = function () {
            $('#tutorialModal').modal('hide');
            $('#newOrder').modal('show');
            $scope.newTaskModal();
            $scope.orderFromTour = true;
        }

        $(window).on('shown.bs.modal', function () {
            // Tawk_API.hideWidget();
        });

        $(window).on('hidden.bs.modal', function () {
            // Tawk_API.showWidget();
        });


        $scope.refreshData = function (page) {
            $scope.$broadcast('refresh_data');
        }

        $scope.newData = function (page) {
            $scope.$broadcast('new_data');
        }

        $scope.editData = function (data) {
            $scope.$broadcast('edit_data', data);
        }

        $scope.callDriver = function (data) {
            $scope.$broadcast('call_driver', data);
        }

        $scope.openLightboxModal = function (index) {
            Lightbox.openModal($scope.selectedWork.images, index);
        };

        $scope.openImage = function (image) {
            var data = [
                'https://s3.eu-central-1.amazonaws.com/bovo/' + image
            ];
            console.log(data);
            Lightbox.openModal(data, 0);
        };

        $scope.filterProp = [];

        $scope.addFilterProps = function (item) {
            if ($scope.filterProp.indexOf(item) === -1)
                $scope.filterProp.push(item);
            else {
                var index = $scope.filterProp.indexOf(item);
                $scope.filterProp.splice(index, 1);
            }
        }

        $scope.searchStock = function ($select, type) {
            if (type != lastType) $scope.searchRes = [];
            if ($select.search) {
                return $http.get('api/find-stock', {
                    params: {
                        s: $select.search,
                        type: type
                    },
                    ignoreLoadingBar: true
                }).then(function (response) {
                    $scope.searchRes = response.data.stocks;
                });
                lastType = type;
            };
        };

        $scope.removeFault = function (index) {
            $scope.newOrderData.faults.splice(index, 1);
        }

        $scope.addOrderFault = function () {
            if (typeof $scope.newOrderData.faults === 'undefined') {
                $scope.newOrderData.faults = [];
            }
            $scope.newOrderData.faults.push({
                stock_id: '',
                description: ''
            });
        }

        $scope.removeChecklist = function (index) {
            $scope.newOrderData.checklists.splice(index, 1);
        }

        $scope.addChecklist = function () {
            if (typeof $scope.newOrderData.checklists === 'undefined') {
                $scope.newOrderData.checklists = [];
            }
            $scope.newOrderData.checklists.push({
                stock_id: '',
                description: ''
            });
        }

        $scope.updateChecklist = function (item) {
            Order.updateChecklist(item).success(function (data, status, headers, config) {

            });
        }

        $scope.downloadExcelActivity = function () {
            Order.exportOrderActivity().success(function (data) {
                window.location.href = data['url'];
                console.log(data);
            })
        }

        $scope.calculateChecklist = function (checklist) {
            var count = checklist.length,
                completed = 0;
            checklist.forEach(element => {
                if (element.status == 1 || element.status == 2) {
                    completed++
                }
            });
            return Number((completed * 100) / count).toFixed(2);
        }

        $scope.copyToClipBoard = function (text) {
            navigator.clipboard.writeText(text).then(function () {
                alert("Kopyalandı");
            }, function (err) {
                console.error('Async: Could not copy text: ', err);
            });
        }

        $scope.completeDayend = function (dayend) {
            Dayend.completeDayend({ id: dayend.id }).success(function (data, status, headers, config) {
                $scope.data.dayend.status = true;
                $scope.$broadcast('completeDayend', dayend);
            });
        }

        $scope.unCompleteDayend = function (dayend) {
            Dayend.unCompleteDayend({ id: dayend.id }).success(function (data, status, headers, config) {
                $scope.data.dayend.status = false;
                $scope.$broadcast('unCompleteDayend', dayend);
            });
        }

        $scope.excelExport = async function (id) {
            console.log(id);
            await Order.exportOrderMoves(id).success(function (data) {
                window.location.href = data['url'];
            })
        }

        $scope.voyageExportToExcel = async function (voyage, type) {
            console.log(voyage);
            await Voyage.exportExcel({ voyage_id: voyage.id, type: type }).success(function (data) {
                window.location.href = data['url'];
            })
        }

        $scope.downloadExcelDayend = function () {
            var data = {
                orders: []
            };
            $scope.data.dayend.orders.forEach(element => {
                data.orders.push(element.id);
            });
            Order.generateExcelReport(data).success(function (data, status, headers, config) {
                window.location.href = data['url'];
                setTimeout(function () {
                    Order.removeDownloadFile({ path: data['path'] }).success(function (response, status, headers, config) {
                        $scope.selectedItem = [];
                    });
                }, 2000)
            });
        };

        $scope.resetRoute = function () {
            $location.search({});
        }

        $scope.openPaletteDetail = function (data) {
            Pallette.get(data.id ? data.id : data).success(function (data, status, headers, config) {
                $scope.pallette = data.pallette;
                $scope.orders = data.pallette.orders;
                $('#palletteDetail').modal('show');
            }).error(function (data) {
                Messenger().post({
                    message: data.message,
                    type: "error"
                });
            });
        }

        $scope.openVoyageDetail = function (item, type) {
            Voyage.get(item).success(function (data, status, headers, config) {
                $('#voyageDetail').modal('show');
                $scope.voyageOrderType = 'main';
                $scope.voyage = data.voyage;
                if (!$scope.data) {
                    $scope.data = [];
                }
                $scope.dataShowType = type;
                $scope.data.transfers = data.transfers;
                $scope.data.stations = data.stations;
                $scope.data.customers = data.customers;
                $scope.data.lineVehicles = data.lineVehicles;
                $scope.data.orders = data.orders;
                $scope.data.out = data.out;
                $scope.allOrders = data.orders;
                $scope.notReadedOrders = data.not_readed_orders;
                $scope.outOrders = data.out_orders;
            });
        }

        $scope.closeVoyage = function (voyage, type) {
            if (confirm('Bu sefer kapatılacaktır, onaylıyor musunuz ?')) {
                Voyage.closeVoyage({ voyage_id: voyage.id }).success(function (data, status, headers, config) {
                    Messenger().post('Başarılı');
                    $('#voyageDetail').modal('hide');
                    window.open('/print-voyage/' + voyage.id, '_blank');
                    $scope.openDetail(voyage)
                }).error(function (data) {
                    Messenger().post({
                        message: data.message,
                        type: "error"
                    });
                });
            }
        }

        $scope.createGroupBarcode = function (voyage) {
            window.open('/print-order?voyage_id=' + voyage.id + '&type=voyage', '_blank');
        }

        $scope.resolveWarning = function (warning) {
            if (confirm('Bu uyarı çözüldü durumuna geçecektir, onaylıyor musunuz ?')) {
                Order.resolveWarning({ warning_id: warning.id }).success(function (data, status, headers, config) {
                    Messenger().post('Başarılı');
                    $scope.getOrderDetails(warning.order_id)
                }).error(function (data) {
                    Messenger().post({
                        message: data.message,
                        type: "error"
                    });
                });
            }
        }


        $scope.batchMovement = function (voyage, type) {
            if (voyage.code) {
                var code = prompt("Lütfen Mühür Numarasını Giriniz!", "Mühür Numarası");
            }
            var formParams = {
                code: code,
                voyage_id: voyage.id,
                type: type
            };
            Voyage.batchMovement(formParams).success(function (data, status, headers, config) {
                Messenger().post('Başarılı');
                $('#voyageDetail').modal('hide');
                $scope.openDetail(voyage)
            }).error(function (data) {
                Messenger().post({
                    message: data.message,
                    type: "error"
                });
            });
        }


        $scope.voyageOrderType = 'main';
        $scope.setVoyageOrders = function (type) {
            $scope.voyageOrderType = type;
            if ($scope.voyageOrderType == 'all') {
                $scope.data.orders = $scope.allOrders
            } else if ($scope.voyageOrderType == 'not_read') {
                $scope.data.orders = $scope.notReadedOrders
            } else if ($scope.voyageOrderType == 'out_orders') {
                $scope.data.orders = $scope.outOrders
            }
        }
    }
]);